
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import functions from "@/functions";
import Dialog from "@/components/Parts/Dialog.vue";
import sortList from "@/common/helpers/utilities";
import cloneDeep from "lodash/cloneDeep"
import draggable from 'vuedraggable';
import jexl from 'jexl';

@Component({
    components: {
        Dialog,
        draggable
    }
})

  export default class CustomButtons extends Vue {
    @Prop({required: true}) customButtons: any;
    @Prop({required: true}) formData: any;
    @Prop({required: true}) showExportDataButton: any;
    showExportDataModal = false
    fileTypeOptions = ['xlsx', 'xml']
    exportSettings = {
        includeRelatedFieldIDs: false,
        fileType: 'xlsx',
        fileName: `Export`,
        includeHeaders: true,
        showTotalRow: true,
        allData: true
    }
    firstCustomButton = null
    customButtonsList = []
    dbCustomButtons = {}

    created() {
        if(!this.customButtons || !Object.keys(this.customButtons).length) {
            const label: any = this.$t('common.buttons.actions')
            this.firstCustomButton = {
                actions: [],
                executableWhenLocked: true,
                label: label,
                name: "actions",
                type: "systemButton"
            }
        } else {
            this.dbCustomButtons = cloneDeep(this.customButtons)
            for(const customButton in this.customButtons) {
                this.customButtonsList.push(this.customButtons[customButton])
            }
            this.customButtonsList = sortList(this.customButtonsList, 'order')
            this.firstCustomButton = this.customButtonsList[0]
        }

    }

    buttonLocked(cbutton) {
        let emptyField = false
        if(cbutton && cbutton.disableExpression) {
            try {
                emptyField = jexl.evalSync(cbutton.disableExpression, this.formData.data)
            } catch  {
                return true
            }
        }
        return this.formData?.locked && !cbutton?.executableWhenLocked || emptyField
    }

    openExportDataModal() {
        this.showExportDataModal = true
        const formattedDate = this.formatDate(new Date())
        this.exportSettings = {
            includeRelatedFieldIDs: false,
            fileType: 'xlsx',
            fileName: `Export ${formattedDate}`,
            includeHeaders: true,
            showTotalRow: true,
            allData: true
        }
        return
    }

    doCustomButtonAction(customButton) {
      if(this.buttonLocked(customButton)) {
        return
      }
      const actions = customButton.actions.sort((a, b) => {
        return a.order - b.order;
      });
      actions.forEach(action => {
        const runAction = functions.httpsCallable('runAction');
        //TODO dev mode
        //functions.useEmulator('localhost', 5001);

        const tenantID = store.state.tenantID;
        const parameters: any = {};
        parameters.ID = this.formData.data.ID;
        parameters.module = this.formData.module;
        const data = {ID: action.ID, tenantID, type: action.type, parameters};
        runAction(data).then((res) => {
          this.$q.notify({
            color: "positive",
            message: customButton.successMessage,
          });
            this.refreshDataHandler()
        }).catch((err) => {
            this.$q.notify({
                color: "negative",
                message: customButton.errorMessage,
            });
        })
      });
    }

    formatDate(date: Date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    @Watch('customButtonsList')
    customButtonsListHandler() {
        let orderChanged = false
        this.firstCustomButton = this.customButtonsList[0]
        let index = 0
        for(const customButton of this.customButtonsList) {
            customButton.order = index
            if(customButton.order !== this.dbCustomButtons[customButton.name].order) {
                orderChanged = true
            }
            index += 1
        }
        if(orderChanged) this.customButtonsChangedHandler()
    }

    @Emit('customButtonsChanged')
    customButtonsChangedHandler() {
        return this.customButtonsList
    }

    @Emit('refreshData')
    refreshDataHandler() {
        return true
    }

    @Emit('exportData')
    exportDataHandler() {
        return this.exportSettings
    }
  }
