
import Utils from "@/utils/Utils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { useModulesStore } from "../../../Modules/Store/ModuleStore"
import { useRecordsStore } from "@/components/Records/Store/RecordStore";
import StatusValidation from "@/components/Base/Parts/StatusValidation.vue"
import store from "@/store";

@Component({
  components: {StatusValidation}
})
export default class StatusFieldEditor extends Vue {
    value = null;
    loaded = false;
    params: any = null
    moduleID = null;
    field = null;
    formData = null;
    timeoutID = null;
    selectElement: HTMLElement = null;
    hasErrorOrEmpty = false;
    showDialog = false;
    modulesStore = useModulesStore()
    recordsStore = useRecordsStore()
    statusOptions: any = [];

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
        clearTimeout(this.timeoutID)
    }

    async mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        this.statusOptions = this.loadAvailableStatusOptions()
        this.formData = await this.generateFormData()
        const elements = document.getElementsByClassName("ag-popup-editor")
        const quasarSelects = document.getElementsByClassName("quasar-select")
        this.timeoutID = setTimeout(() => {
            for(const select of quasarSelects) {
                select['__vue__'].showPopup()
            }
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
            this.setupKeyListeners();
        }, 1);
    }

    setupKeyListeners() {
        const app = this
        this.selectElement = document.getElementById('status-select')
        this.selectElement.addEventListener('keyup', function(event) {
            if (event.key === 'Enter') {
                app.params.stopEditing()
            }
        });
    }

    async generateFormData() {
        const module = this.modulesStore.getModule(this.moduleID)
        const data = await this.recordsStore.getDoc(this.moduleID, this.params.data.ID)
        return {
            dynamicSections: module.sections,
            data: data,
            dynamicFields: module.fields,
            editingFields: []
        }
    }

    isValid() {
      if(this.hasErrorOrEmpty) {
          return false
      }
      if(Utils.isEmpty(this.value)) {
        return false
      }
      return true
    }

    getValue() {
      if(!this.isValid()) {
        return this.params.value;
      }
      return this.modulesStore.modules[this.moduleID].fields[this.params.colDef['field']].getUnformattedValue(this.value)
    }

    get fieldValue(): number {
        this.moduleID = this.params.context.componentParent.getModuleID()
        this.field = this.modulesStore.getField(this.moduleID, this.params.colDef['field'])
        if (this.params) {
            if (this.params.value) {
                const translationKey = `${this.moduleID}.dropDowns.${this.field.name}.${this.params.value}`
                if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                    return store.state.fieldTranslations[translationKey]
                }
                return this.params.value
            }
        }
        return null;
    }

    loadAvailableStatusOptions() {
        const availableStatusOptions = []
        for(const s in this.field.metaData) {
            const status = this.field.metaData[s]
            if(status.label === this.params.value) {
                if(!status.canTransitionTo) continue
                for(const possibleOption of status.canTransitionTo) {
                    this.field.metaData[possibleOption].translatedLabel = this.field.metaData[possibleOption].label
                    const translationKey = `${this.moduleID}.dropDowns.${this.field.name}.${this.field.metaData[possibleOption].label}`
                    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                        this.field.metaData[possibleOption].translatedLabel = store.state.fieldTranslations[translationKey]
                    }
                    availableStatusOptions.push(this.field.metaData[possibleOption])
                }
            }
        }
        return availableStatusOptions
    }

    emitFormData(value) {
        this.formData = value
        const translatedValue = this.getTranslatedValue(this.moduleID, this.field.name, value.data.status)
        this.value = translatedValue
    }

    getTranslatedValue(moduleID: string, fieldName: string, newValue: string): string | null {
        const translationKey = `${moduleID}.dropDowns.${fieldName}.${newValue}`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
            return store.state.fieldTranslations[translationKey]
        }
        return newValue;
    }

    closeDialog(value) {
      this.showDialog = value
    }

    @Watch('value') 
    valueHandler(){
        if(this.field.mandatory) {
            if(Utils.isEmpty(this.value)) {
                this.hasErrorOrEmpty = true
                return
            }
        }
        this.hasErrorOrEmpty = false
    }
}
