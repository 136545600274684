import {collection, deleteDoc, doc, getDoc, getDocs, setDoc} from "firebase/firestore";
import db from '@/db';
import store from '@/store';
import Utils from '@/utils/Utils';
import { defineStore } from 'pinia'
import TableView from '../Models/TableView';
import OrganizationColumns from '../Models/OrganizationColumns';
import CachedTableViews from '../Models/CachedTableViews';
import { TableConstraints } from "../Constraints/TableConstraints";
import { TableType } from "../Models/TableType";

export const useTablesStore = defineStore('tables', {
  state: (): {
    defaultOrganizationColumnsLoaded: boolean;
    defaultOrganizationColumns: OrganizationColumns;
    cachedTableViews: CachedTableViews;
    currentPage: any;
  } => ({
    defaultOrganizationColumnsLoaded: false, 
    defaultOrganizationColumns: {},
    cachedTableViews: {},
    currentPage: {}
  }),
  getters: {
    async organizationColumns(): Promise<OrganizationColumns> {
        if(!this.defaultOrganizationColumnsLoaded){
          const ref = doc(db, `tenants/${store.state.tenantID}/settings/${TableConstraints.DEFAULT_COLUMNS_DOC_ID}`);
          const columns = (await getDoc(ref)).data();
          this.defaultOrganizationColumnsLoaded = true;
          this.defaultOrganizationColumns = columns as OrganizationColumns;
        }
      return this.defaultOrganizationColumns
    },
  },
  actions: {
    async updateOrganizationColumns(defaultOrganizationColumns: OrganizationColumns): Promise<void>{
      this.defaultOrganizationColumns = defaultOrganizationColumns;
      const ref = doc(db, `tenants/${store.state.tenantID}/settings/${TableConstraints.DEFAULT_COLUMNS_DOC_ID}`);
      await setDoc(ref, defaultOrganizationColumns, {merge: true});
      //TODO catch then, return updated or error
      return
    },
    async updateLastUsedView(view: any, tableID: string) { 
      this.cachedTableViews[tableID] = view;
      if(!store.state.settings.lastUsedTableViews) {
        store.state.settings.lastUsedTableViews = {}
      }
      store.state.settings.lastUsedTableViews[tableID] = view && view.ID ? view.ID : 'defaultView';
      const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/preferences/${TableConstraints.LAST_USED_TABLE_VIEWS_DOC_ID}`)
      await setDoc(ref, {[tableID]: view.ID}, {merge: true});
    },
    async updateRecordPerPage(recordsPerPage: number, tableID: string) { 
      if(!store.state.settings.recordsPerPage) {
        store.state.settings.recordsPerPage = {}
      }
      store.state.settings.recordsPerPage[tableID] = recordsPerPage;
      const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/preferences/${TableConstraints.RECORDS_PER_PAGE_DOC_ID}`)
      await setDoc(ref, {[tableID]: recordsPerPage}, {merge: true});
    },
    async saveUserView(currentView: TableView, tableID: string) {
      if(!Utils.isEmpty(currentView)) {
        const docID = currentView && currentView.ID ? currentView.ID : (db.collection(`tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/tableViews/${tableID}/records`).doc()).id
        currentView.ID = docID;
        const view: any = currentView
        this.cachedTableViews[tableID] = view;
        const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/tableViews/${tableID}/records/${docID}`);
        await setDoc(ref, currentView, {merge: true});
      }
    },
    async deleteUserView(currentView: TableView, tableID: string) {
      if(!Utils.isEmpty(currentView)) {
        const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/${TableConstraints.USER_TABLE_VIEWS_COL_ID}/${tableID}/records/${currentView.ID}`);
        await deleteDoc(ref);
      }
    },
    async saveSharedView(currentView: TableView, tableID: string) {
      if(!Utils.isEmpty(currentView)) {
        const docID = currentView && currentView.ID ? currentView.ID : (db.collection(`tenants/${store.state.tenantID}/shared/tableViews/${tableID}`).doc()).id
        currentView.ID = docID
        const view: any = currentView
        this.cachedTableViews[tableID] = view;
        const ref = doc(db, `tenants/${store.state.tenantID}/shared/${TableConstraints.SHARED_TABLE_VIEWS_COL_ID}/${tableID}/${docID}`);
        await setDoc(ref, currentView, {merge: true});
      }
    },
    async deleteSharedView(currentView: TableView, tableID: string) {
      if(!Utils.isEmpty(currentView)) {
        const ref = doc(db, `tenants/${store.state.tenantID}/shared/${TableConstraints.SHARED_TABLE_VIEWS_COL_ID}/${tableID}/${currentView.ID}`);
        await deleteDoc(ref);
      }
    },
    async getUserTableViews(tableID: string){
      //TODO create Table view model and add as return value
      const ref = collection(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/${TableConstraints.USER_TABLE_VIEWS_COL_ID}/${tableID}/records`);
      return await getDocs(ref).then(sn => { return sn.docs.map(doc => doc.data())});
    },
    async getSharedTableViews(tableID: string){
      //TODO create Table view model and add as return value
      const ref = collection(db, `tenants/${store.state.tenantID}/shared/${TableConstraints.SHARED_TABLE_VIEWS_COL_ID}/${tableID}`);
      return await getDocs(ref).then(sn => { return sn.docs.map(doc => doc.data())});
    },
    async getTableViews(tableID: string) {
      //TODO create Table view model and add as return value
      const userTableViews = await this.getUserTableViews(tableID);
      const sharedTablesViews = await this.getSharedTableViews(tableID);
      return userTableViews.concat(sharedTablesViews)
    },
    async getTableViewByID(tableID: string, tableViewID: string, type?: any) {
      if(type && !Utils.isEmpty(this.cachedTableViews[tableID]) && type == TableType.MODULE_OVERVIEW) {
        if(this.cachedTableViews[tableID]['ID'] === tableViewID) {
          return this.cachedTableViews[tableID]
        }
      }
      let ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/${TableConstraints.USER_TABLE_VIEWS_COL_ID}/${tableID}/records/${tableViewID}`);
      let view = await getDoc(ref)
      if(!view.exists()){
        ref = doc(db, `tenants/${store.state.tenantID}/shared/${TableConstraints.SHARED_TABLE_VIEWS_COL_ID}/${tableID}/${tableViewID}`);
        view = await getDoc(ref);
      }
      if(type && type == TableType.MODULE_OVERVIEW) {
        this.cachedTableViews[tableID] = view.data() as any
        return this.cachedTableViews[tableID]
      }
      return view.data()
    },
  },
})

export default useTablesStore