
import { Prop, Vue, Component, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import db from "@/db";
import DynamicField from "@/components/Forms/DynamicField.vue";
import Utils from "@/utils/Utils";

@Component({
  components: {DynamicField},
})


export default class StatusValidation extends Vue {
    @Prop() formData;
    @Prop() modalStep;
    @Prop() calledFromFieldEditor;
    @State('dynamicModules') dynamicModules;
    dialog = false  
    dialogFields = []
    dialogSectionErrors = []
    dialogSectionsValid = true
    currentStatus = null
    validationList = {}
    resetValidationList = {}
    requiredFieldValidated = false
    checklist = []
    dynamicFields = null
    requiredDynamicFields = null
    status = null

    computed() {
      const dynamicFields = this.formData.dynamicFields;
      const mandatoryFields = [];
    
      for (const field in dynamicFields) {
        if (dynamicFields[field]?.mandatory === true) {
          const fieldValue = this.formData.data[dynamicFields[field].name];
          const data = this.dataToString(fieldValue);
          if(!data) {
            mandatoryFields.push(dynamicFields[field]);
          }
        }
      }
      this.requiredDynamicFields = mandatoryFields
      this.dynamicFields = this.formData.dynamicFields || {};
    }

    mounted(){
        this.status = this.modalStep
        this.currentStatus = this.modalStep
        const dynamicFields = this.formData.dynamicFields;
        const mandatoryFields = [];
        for (const field in dynamicFields) {
          if (dynamicFields[field]?.mandatory === true && !dynamicFields[field].hidden) {
            const fieldValue = this.formData.data[dynamicFields[field].name];
            const data = this.dataToString(fieldValue);
            if(!data) {
                mandatoryFields.push(dynamicFields[field]);
            }
          }
        }
        this.requiredDynamicFields = mandatoryFields
        this.dynamicFields = this.formData.dynamicFields || {};
        this.openDialog(this.status);
    }

    getAllRequiredFields(status) {
      if(!Utils.isEmpty(status.requiredFields)) {
        return [...status.requiredFields, ...this.requiredDynamicFields.map(field => field.name)];
      } else {
        return [...this.requiredDynamicFields.map(field => field.name)]
      }
    }

    getAllEmptyRequiredFields(fields) {
      return fields.map(field => {
        const fieldValue = this.formData.data[field];
        const data = this.dataToString(fieldValue);
        if(!data) {
          return field;
        }
      })
    }

    addFieldToValidationList(data) {
      this.validationList[data.name] = data.validate;
      this.resetValidationList[data.name] = data.resetValidation;
    }

    doValidate() {
      let error = false;
      for(const field in this.resetValidationList) {
        this.resetValidationList[field]();
      }
      for(const field in this.validationList) {
        if (!this.validationList[field]()) {
          error = true;
        }
        if(typeof this.formData.data[field] == "boolean" && this.formData.data[field] == false) {
          error = true
        }
      }
      for(const field of this.checklist) {
        if (field.value !== true) {
          error = true
        }
      }
      return !error;
    }

    doPreValidate(requiredFields) {
      for (const option of requiredFields) {
        if(Utils.isRelatedFieldNotFilled(this.formData["data"][option])) {
          return false
        }
      }
      return true;
    }

    async openDialog(status) {
      if(this.isStatusActive(status.label)) {
        this.closeDialog()
        return;
      }
      await this.validateRequiredSections(status)

      this.dialogFields = []

      this.checklist = []
      if(this.status.checklist) {
        for(const field of this.status.checklist) {
          const map = {
            name: field,
            value: false
          }
          this.checklist.push(map)
        }
      }
      const requiredFields = this.getAllRequiredFields(status);
      const nonEmptyRequiredFields = this.getAllEmptyRequiredFields(requiredFields);

      if (nonEmptyRequiredFields) {
        for (const field of nonEmptyRequiredFields) {
          const dynamicField = this.dynamicFields[field];
          if (dynamicField && !dynamicField.hidden) {
            this.dialogFields.push({...dynamicField});
          }
        }


        if(this.dialogFields.length < 0) {
          this.dialog = false;
          this.formData["data"].status = this.currentStatus
          return;
        }

        const validated = this.doPreValidate(this.dialogFields);
        if(!this.dialogSectionsValid) {
          this.dialog = true
          this.requiredFieldValidated = false
        } else if (this.inValidChecklist()) {
          this.dialog = true
          this.requiredFieldValidated = false      
        } else if (!validated) {
          this.dialog = true
          this.requiredFieldValidated = false
        } else {
          this.requiredFieldValidated = true
          this.formData["data"].status = this.currentStatus.label
          if(this.calledFromFieldEditor) {
            this.emitFormData()
          }
          this.closeDialog()
        }
      } else {
        this.formData["data"].status = this.currentStatus.label
        this.closeDialog()
      }
    }

    inValidChecklist() {
      for(const field of this.checklist) {
        if (field.value !== true) {
          return true
        }
      }
    }

    async validateRequiredSections(status){
      this.dialogSectionErrors = []
      this.dialogSectionsValid = true
      if(!status.requiredSections){return;}
      for(const key in status.requiredSections) {
        const dynSection = this.formData.dynamicSections[key]
        if(this.formData.dynamicSections[key].type === "relatedRecords") {
          await this.checkRelatedRecordsSection(dynSection, status)
        } else if(this.formData.dynamicSections[key].type === "productLines") {
          await this.checkProductLinesSection(dynSection, status)
        } else if(this.formData.dynamicSections[key].type === "media" ) { 
          this.checkMediaSection(dynSection, status)
        } else if(this.formData.dynamicSections[key].type === "attachments" ) { 
          this.checkAttachmentsSection(dynSection, status)
        }
      }
    }

    async checkRelatedRecordsSection(section, status) {
      const records = await db.collection(`tenants/${this.$store.state.tenantID}/modules/${section.relatedModule}/records`)
                      .where(`${section.relatedByField}.ID`, '==', this.formData.data.ID)
                      .limit(status.requiredSections[section.name].minimumAmount).get()

      if(records.size < status.requiredSections[section.name].minimumAmount) {
        if(status.requiredSections[section.name].minimumAmount > 1) {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.dynamicModules[section.name].pluralName.toLowerCase()}`)
        } else {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.dynamicModules[section.name].singularName.toLowerCase()}`)
        }
        this.dialogSectionsValid = false
      }
    }

    async checkProductLinesSection(section, status) {
      const records = await db.collection(`tenants/${this.$store.state.tenantID}/modules/${section.lineType}/records`)
                      .where(`record`, '==', this.formData.data.ID)
                      .limit(status.requiredSections[section.name].minimumAmount).get()

      if(records.size < status.requiredSections[section.name].minimumAmount) {
        if(status.requiredSections[section.name].minimumAmount > 1) {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.dynamicModules[section.name].pluralName.toLowerCase()}`)
        } else {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.dynamicModules[section.name].singularName.toLowerCase()}`)
        }
        this.dialogSectionsValid = false
      }
    }

    checkMediaSection(section, status) {
      const mediaBefore = []
      const mediaDuring = []
      const mediaAfter = []
      if(this.formData.data.media.length > 0) {
        for(const media of this.formData.data.media) {
          if (media.stage == "1") {
            mediaBefore.push(media)
          } else if (media.stage == "2") {
            mediaDuring.push(media)
          } else if (media.stage == "3") {
            mediaAfter.push(media)
          }
        }
      }
      if(mediaBefore.length < status.requiredSections[section.name].minimumAmountBefore) {
        this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmountBefore} media in '${(this.$t('attachments.headers.mediaBefore') as string).toLowerCase()}'`)
      }
      if(mediaDuring.length < status.requiredSections[section.name].minimumAmountDuring) {
        this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmountDuring} media in '${(this.$t('attachments.headers.mediaDuring') as string).toLowerCase()}'`)
      }
      if(mediaAfter.length < status.requiredSections[section.name].minimumAmountAfter) {
        this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmountAfter} media in '${(this.$t('attachments.headers.mediaAfter') as string).toLowerCase()}'`)
      }
    }

    checkAttachmentsSection(section, status) {
      if(this.formData.data.attachments.length < status.requiredSections[section.name].minimumAmount) {
        if(status.requiredSections[section.name].minimumAmount > 1) {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.$t('toolbar.headers.attachments')}`)
        } else {
          this.dialogSectionErrors.push(`- ${ this.$t('status.headers.minimal') } ${status.requiredSections[section.name].minimumAmount} ${this.$t('toolbar.headers.attachment')}`)
        }
        this.dialogSectionsValid = false
      }
    }

    onCancelDialog() {
        this.dialog = false
        this.status = null
        this.emitFormData()
        this.closeDialog()
    }

    onSaveDialog() {
      const validated = this.doValidate();
      if (validated && this.dialogSectionsValid) {
        this.status = null
        this.dialog = false
        this.formData.data.status = this.currentStatus.label
        this.emitFormData()
        this.closeDialog()
      }
    }
    
    isStatusActive(label) {
      return label === this.formData.data["status"];
    }
    
    dataToString(data) {
      if(typeof data === 'string' || typeof data === 'boolean' || typeof data === 'number') {
        return data;
      }
      if (data === null || data === undefined){
        return '';
      }
      if(typeof data === 'object') {
        return data.name;
      }
    }


    @Emit('formData')
    emitFormData() {
        return this.formData
    }
    @Emit('closeValidationDialog')
    closeDialog() {
        return false
    }
}
