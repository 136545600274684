import { ClientSideOperatorType } from './../Models/ClientSideOperatorType';
import db from "@/db";
import store from "@/store";
import ClientSideFilter from '../Models/ClientSideFilter';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community/main';
import SQLHelper from './SQLHelper';
import ElasticHelper from './ElasticHelper';


export default class RowDataHelper {

    static async fetchDataFromFirebase(moduleID: string, recordID: string) {
        return await db.collection(`tenants/${store.state.tenantID}/modules/${moduleID}/records`).where('record', "==", recordID).get().then(sn => { return sn.docs.map(doc => doc.data()) })
    }

    static shouldTableFetchRecords(params: IServerSideGetRowsParams): boolean {
      const { context } = params;
      // If initial load is not dependent on viewport or has already happened, fetch records
      if (!context.initialLoadWhenInViewPort || context.initialLoaded) {
        return true;
      }
      // If initial load is dependent on viewport and table is currently in viewport, fetch records
      if (context.initialLoadWhenInViewPort && context.tableIsInViewPort) {
        return true;
      }
      // Otherwise, don't fetch records
      return false;
    }

    static getDataSource(tenantID: string, moduleID: string, gridApi, columnApi, tableID: string, additionalFilters?: any): IServerSideDatasource {
      return {
        getRows: async (params: IServerSideGetRowsParams) => {
          gridApi.showLoadingOverlay()
          if(!this.shouldTableFetchRecords(params)){
            params.success({
              rowData: [],
              rowCount: 0
            })
            gridApi.hideOverlay()
            return
          }
          const query = SQLHelper.buildQueryFromAGGridFilter(params, tenantID, moduleID, additionalFilters);
          let cursor: string = null;
          let countQuery: string = null;
          if(params.context.previousQuery && !SQLHelper.checkIfTwoQueriesAreTheSame(params.context.previousQuery, query)){
            params.context.elasticCursor = null;
            params.api.refreshServerSide({purge: true})
            params.api.paginationGoToFirstPage();
            countQuery = SQLHelper.buildCountQuery(params, tenantID, moduleID, additionalFilters);
          }
          if(params.request.startRow > 0){
            cursor = params.context.elasticCursor;
          }else{
            countQuery = SQLHelper.buildCountQuery(params, tenantID, moduleID, additionalFilters);
          }
          const columnNames = SQLHelper.getConvertedColumnNames(columnApi, moduleID)
          const elasticData = await ElasticHelper.fetchRecordsFromElasticSQL(query, columnNames, tableID, countQuery, cursor, moduleID)
          .then((data) => {return data})
          .catch((error) => {return error})
          if(elasticData && elasticData.data) {
            const convertedElasticData = ElasticHelper.convertElasticResultToAGRows(elasticData.data.result, moduleID)
            params.context.elasticCursor = elasticData.data.cursor;
            params.context.previousQuery = query;
            if(elasticData.data.count >= 0){
              params.context.count = elasticData.data.count;
            }
            params.success({
              rowData: convertedElasticData,
              rowCount: params.context.count
            })
            params.context.hideOverlayTimeout = setTimeout(() => {
              gridApi.hideOverlay()
            }, 200);
          } else {
            // params.fail()
          }
        }
      }
    }

    static clientSideFiltering(propertyData: any[], filters: ClientSideFilter[]) {
        let data = []
        const operators = {
          [ClientSideOperatorType.ISEQUAL]: function (x, y) {
            return x === y;
          },
          [ClientSideOperatorType.ISNOTEQUAL]: function (x, y) {
            return x !== y;
          },
          [ClientSideOperatorType.GREATERTHAN]: function (x, y) {
            return x > y;
          },
          [ClientSideOperatorType.SMALLERTHAN]: function (x, y) {
            return x < y;
          },
          [ClientSideOperatorType.SMALLERTHANOREQUAL]: function (x, y) {
            return x <= y;
          },
          [ClientSideOperatorType.GREATERTHANOREQUAL]: function (x, y) {
            return x >= y;
          }
        }
        
        if(filters && filters.length) {
          for (const filter of filters) {
            for (const row of propertyData) {
              const checkedValue = operators[filter.operator](row[filter.field],filter.value);
              if(checkedValue) {
                data.push(row)
              }
            }
          }
        } else {
            data = propertyData
        }
        return data
    }

}