
import Utils from "@/utils/Utils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { useModulesStore } from "../../../Modules/Store/ModuleStore"
import { useRecordsStore } from "@/components/Records/Store/RecordStore";
import jexl from 'jexl';
import cloneDeep from "lodash/cloneDeep"
import store from "@/store";

@Component({})
export default class DropdownFieldEditor extends Vue {
    value = null;
    loaded = false;
    params = null
    moduleID = null;
    field = null;
    formData = null;
    hasErrorOrEmpty = false;
    showDialog = false;
    timeoutID = null;
    modulesStore = useModulesStore()
    recordsStore = useRecordsStore()
    dropdownOptions: any = [];

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
      clearTimeout(this.timeoutID)
    }
  
    async mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        this.formData = await this.generateFormData()
        const elements = document.getElementsByClassName("ag-popup-editor")
        const quasarSelects = document.getElementsByClassName("quasar-select")
        this.timeoutID = setTimeout(() => {
            for(const select of quasarSelects) {
                select['__vue__'].showPopup()
            }
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
            this.setupKeyListeners();
        }, 1);
    }

    setupKeyListeners() {
        const app = this
        const element = document.getElementById('dropdown-select')
        element.addEventListener('keyup', function(event) {
            if (event.key === 'Enter') {
                app.params.stopEditing()
            }
        });
    }

    async generateFormData() {
        const module = this.modulesStore.getModule(this.moduleID)
        const data = await this.recordsStore.getDoc(this.moduleID, this.params.data.ID)
        return {
            dynamicSections: module.sections,
            data: data,
            dynamicFields: module.fields,
            editingFields: []
        }
    }

    isValid() {
      if(this.hasErrorOrEmpty) {
          return false
      }
      if(Utils.isEmpty(this.value)) {
        return false
      }
      return true
    }

    getValue() {
      if(!this.isValid()) {
        return this.params.value;
      }
      return this.modulesStore.modules[this.moduleID].fields[this.params.colDef['field']].getUnformattedValue(this.value)
    }

    get fieldValue(): number {
        this.moduleID = this.params.context.componentParent.getModuleID()
        this.field = this.modulesStore.getField(this.moduleID, this.params.colDef['field'])
        if (this.params) {
            if (this.params.value) {
              const translationKey = `${this.moduleID}.dropDowns.${this.field.name}.${this.params.value}`
              if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                return store.state.fieldTranslations[translationKey]
              }
              return this.params.value
            }
        }
        return null;
    }

    getTranslatedValue(): string | null {
      const translationKey = `${this.moduleID}.dropDowns.${this.field.name}.${this.value}`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        this.value = store.state.fieldTranslations[translationKey]
        return;
      }
      return;
    }


      async filterAutocomplete(val, update) {
        const module = this.modulesStore.getModule(this.moduleID)
        let filterOptions = module.dropDownValues[this.field.name]
        if(this.field.dropdownExpression) {
          let value = null
          if(this.field.dropdownExpression.includes('.')) {
            const formData = await this.getDataForDropDownExpression(this.field.dropdownExpression)
            value = await jexl.eval(this.field.dropdownExpression, formData).catch(() => {
              return null
            })
          } else {
            value = await jexl.eval(this.field.dropdownExpression, this.formData.data).catch(() => {
              return null
            })
          }

          if(!Utils.isEmpty(value)) {
            filterOptions = module.dropDownValues[value]
            const translatedDropdownOptions = []
            for(const dropdownValue of filterOptions) {
              const translationKey = this.moduleID  + '.' + this.field.name + '.' + dropdownValue
              if(store.state && store.state.dropDownMap && store.state.dropDownMap[translationKey]) {
                translatedDropdownOptions.push(store.state.dropDownMap[translationKey])
              } else {
                translatedDropdownOptions.push(dropdownValue)
              }
            }
            filterOptions = translatedDropdownOptions
          } else {
            filterOptions = []
          }
        }
        update(() => {
          const needle = val.toLocaleLowerCase();
          this.dropdownOptions = filterOptions.filter(
            (v) => {
              const name = v.name ? v.name : v;
              return String(name).toLocaleLowerCase && String(name).toLocaleLowerCase().indexOf(needle) > -1
            }
          );
        });
      }

      async getDataForDropDownExpression(dropdownExpression) {
        const data = dropdownExpression.split('.');
        const formData = cloneDeep(this.formData.data);
        for(const val of data) {
          const field = val.split(' ')[val.split(' ').length -1]
          if(!field.includes("'")) {
            const relatedModule = this.formData.dynamicModule.fields[field].relatedModule
            let relatedFieldData = formData[field]
            if(!Utils.isEmpty(formData[field].ID)) {
              relatedFieldData = await this.recordsStore.getDoc(relatedModule, formData[field].ID)
            }
            formData[field] = relatedFieldData
          }
        }
        return formData
      }

    @Watch('value') 
    valueHandler(){
        if(this.field.mandatory) {
            if(Utils.isEmpty(this.value)) {
                this.hasErrorOrEmpty = true
                return
            }
        }
        this.hasErrorOrEmpty = false
    }
}
