
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class CheckboxFieldRenderer extends Vue {
    value = null;
    loaded = false;
    params: ICellRendererParams = null

    setup(props: any) {
        this.params = props.params
    }
    mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
    }

    get fieldValue(): boolean {
        if (this.params) {
            if (this.params.value === true) {
                return true;
            } else if (this.params.value === false || this.params.value === undefined || this.params.value === null) {
                return false;
            }
        }
        return false;
    }
}
