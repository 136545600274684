import { render, staticRenderFns } from "./StatusValidation.vue?vue&type=template&id=7f437ca4&scoped=true&"
import script from "./StatusValidation.vue?vue&type=script&lang=ts&"
export * from "./StatusValidation.vue?vue&type=script&lang=ts&"
import style0 from "./StatusValidation.vue?vue&type=style&index=0&id=7f437ca4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f437ca4",
  null
  
)

export default component.exports
import {QDialog,QCard,QItem,QItemSection,QItemLabel,QCardSection,QCheckbox,QCardActions,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QItem,QItemSection,QItemLabel,QCardSection,QCheckbox,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
