import { ColumnApi, GridApi, RowNode } from "ag-grid-enterprise";
import useModulesStore from "@/components/Modules/Store/ModuleStore";
import SQLHelper from "./SQLHelper";
import { FieldType } from "@/components/Fields/Models/FieldType";
import ElasticHelper from "./ElasticHelper";
import VueI18n  from "@/i18n"
import { TableType } from "../Models/TableType";
import Utils from "@/utils/Utils";

export default class AGGridHelper {
    static getActiveColumnIDs(columnApi: ColumnApi): Array<string>{
        const activeColumns = columnApi.getAllDisplayedColumns()
        const columnNames: Array<string> = []
        for(const activeColumn of activeColumns) {
            columnNames.push(activeColumn.getColId())
        }
        return columnNames;
   }

   static getColumnsEligibleForAggregation(columnApi: ColumnApi, moduleID: string, type: TableType): Array<any> {
        const moduleStore = useModulesStore();
        const columns = columnApi.getColumns();
        const columnsEligibleForAggregation: Array<any> = []
        for (const column of columns) {
            const fieldName = column.getColId();
            const field = moduleStore.getField(moduleID, fieldName)
            if(field && field.canAggregate(type)){
                const type = field.getFieldType() === FieldType.CALCULATEDFIELD ? field['fieldType'] : field['type']
                columnsEligibleForAggregation.push({name: fieldName, type: type});
            }
        }
        return columnsEligibleForAggregation
   }

   static async generatePinnedBottomRowDataServerSide(columnApi: ColumnApi, tenantID: string, moduleID: string, tableID: string, filterData: any, type: TableType, additionalFilter?: any): Promise<any> {
        const result = {};
        const columns = this.getColumnsEligibleForAggregation(columnApi, moduleID, type)
        if(columns.length){
            const firstColumn = columnApi.getAllDisplayedColumns()[0];
            const moduleStore = useModulesStore();
            const field = moduleStore.getField(moduleID, firstColumn?.getColId())
            if(field?.getFieldType() !== FieldType.RELATEDFIELD) {
                result[firstColumn?.getColId()] = VueI18n.t('productLines.headers.total');
            } else {
                result[firstColumn?.getColId()] = {ID: "", name: VueI18n.t('productLines.headers.total')};
            }
        }
        const SQLQuery = SQLHelper.buildAggregationQuery(columns, tenantID, moduleID, filterData, additionalFilter)
        const actualColumns = []
        for(const column of columns) {
            actualColumns.push(`${moduleID}_${column.name}`)
        }
        if(!actualColumns.length) return {}
        const elasticResponse = await ElasticHelper.fetchRecordsFromElasticSQL(SQLQuery, actualColumns, tableID)
        const sqlResult = elasticResponse && elasticResponse.data && elasticResponse.data.result ? elasticResponse.data.result : null
        if(sqlResult) {
            for (const column of columns) {
                const fieldName = `${moduleID}_${column.name}`
                const value = sqlResult[0][fieldName]
                if(value) result[column.name] = column.type == FieldType.DECIMAL ? value : value.toString();
            }
        }
        return result
    }

    static generatePinnedBottomRowDataClientSide(columnApi: ColumnApi, moduleID: string, gridApi: GridApi, type: TableType): any {
        const result = {};
        const columns = this.getColumnsEligibleForAggregation(columnApi, moduleID, type)
        if(columns.length){
            const firstColumn = columnApi.getAllDisplayedColumns()[0];
            if(!firstColumn) return;
            const moduleStore = useModulesStore();
            const field = moduleStore.getField(moduleID, firstColumn.getColId())
            if(field.getFieldType() !== FieldType.RELATEDFIELD) {
                result[firstColumn.getColId()] = VueI18n.t('productLines.headers.total');
            } else {
                result[firstColumn.getColId()] = {ID: "", name: VueI18n.t('productLines.headers.total')};
            }
        }
        const columnNames = []
        for(const column of columns) {
            columnNames.push(column.name)
            let value = 0
            const selectedRows = gridApi.getSelectedNodes()
            if (selectedRows.length) {
                for(const selectedRow of selectedRows) {
                    if(!Utils.isEmpty(selectedRow.data[column.name])) {
                        value = value + selectedRow.data[column.name]
                    }
                }
            } else {
                gridApi.forEachNodeAfterFilter((rowNode, index) => {
                    if(!Utils.isEmpty(rowNode.data[column.name])) {
                        value = value + rowNode.data[column.name]
                    }
                });
            }
            result[column.name] = column.type == FieldType.DECIMAL ? value : value.toString();
        }
        if(columnNames.includes('marginPercentage')) {
            const subTotalSales = result["subtotalSales"] / 10000
            let subTotalPurchaseOrCost = result["subtotalPurchase"]
            if(!Utils.isEmptyUndefinedNullOrInvalidNumber(result['subtotalCost'])){
                subTotalPurchaseOrCost = result['subtotalCost']
            }
            subTotalPurchaseOrCost = subTotalPurchaseOrCost / 10000
        
            let totalValue = 0
            if(subTotalSales !== 0 && subTotalPurchaseOrCost !== 0) {
              const subTotalValue = subTotalSales - subTotalPurchaseOrCost
              totalValue = (subTotalValue/subTotalSales) * 1000000
            }
            result['marginPercentage'] = totalValue
        }
        return result
    }
}
