import functions from "@/functions";
import useModulesStore from "@/components/Modules/Store/ModuleStore";
import { FieldType } from "@/components/Fields/Models/FieldType";
import Utils from "../../../utils/Utils";
import store from "@/store";

export default class ElasticHelper {
  static async fetchRecordsFromElasticSQL(
    query: string,
    columns: any,
    tableID: string,
    countQuery?: string,
    cursor?: string,
    moduleID?: string,
  ): Promise<any> {
    // functions.useEmulator('localhost', 5001);
    const fetchSize = store.state.settings && store.state.settings.recordsPerPage && store.state.settings.recordsPerPage[tableID] ? store.state.settings.recordsPerPage[tableID] : 20
    const elasticSQL = functions.httpsCallable("elasticSQL");
    const request = {
      query,
      countQuery,
      fetchSize: fetchSize,
      cursor,
      moduleID,
      columns,
    };
    try {
      const response = await elasticSQL(request);
      return response;
    } catch (error) {
      console.log("elastic error: ", error);
      return error;
    }
  }

  static fieldNameToElasticSearchFieldName(
    fieldName: string,
    moduleID: string
  ): string {
    const moduleStore = useModulesStore();
    const field = moduleStore.getField(moduleID, fieldName);
    let fieldType = field.getFieldType();
    if(fieldType === FieldType.CALCULATEDFIELD) {
      fieldType = field['fieldType']
    }
    if (fieldType === FieldType.RELATEDFIELD) {
      return `${moduleID}_${fieldName}.name`;
    } else if (fieldType === FieldType.DATETIME) {
      return `${moduleID}_${fieldName}.seconds`;
    } else if (fieldName === "ID") {
      return fieldName;
    }
    return `${moduleID}_${fieldName}`;
  }

  static convertFieldNamesToElasticFieldNames(
    fieldNames: Array<string>,
    moduleID: string
  ): Array<string> {
    /**
     * Elastic field names are different than the field names stored in the database, this function convert field names to Elastic fieldname.
     * For example we have the following field names, where workOrder is of field type RELATEDFIELD and changedAt is of type DATETIME
     * [
     *      "name",
     *      "workOrder",
     *      "changedAt",
     *      "ID"
     * ]
     * this function converts the above examples to:
     * [
     *      "invoices_name",
     *      "invoices_workOrder.ID",
     *      "invoices_workOrder.name",
     *      "invoices_changedAt.nanoseconds",
     *      "invoices_createdAt.seconds",
     *      "ID"
     * ]
     */
    const convertedFields: Array<string> = [];
    const moduleStore = useModulesStore();
    for (const fieldName of fieldNames) {
      const field = moduleStore.getField(moduleID, fieldName);
      if(!field) continue
      let fieldType = field.getFieldType();
      if(fieldType === FieldType.CALCULATEDFIELD) {
        fieldType = field['fieldType']
      }
      if (fieldType === FieldType.RELATEDFIELD) {
        convertedFields.push(`${moduleID}_${fieldName}.name`);
        convertedFields.push(`${moduleID}_${fieldName}.ID`);
      } else if (fieldType === FieldType.DATETIME) {
        convertedFields.push(`"${moduleID}_${fieldName}.nanoseconds"`);
        convertedFields.push(`"${moduleID}_${fieldName}.seconds"`);
      } else if (fieldName === "ID") {
        convertedFields.push(fieldName);
      } else {
        convertedFields.push(`${moduleID}_${fieldName}`);
      }
    }
    return convertedFields;
  }

  static convertElasticResultToAGRows(
    results: Array<any>,
    moduleID: string
  ): Array<any> {
    /**
     * Elastic returns the data with different keys than we use for our field IDs.
     * For example Elastic returns the data in the following format
     * {
     *      "invoices_name": "someName",
     *      "invoices_workOrder.ID": "someID",
     *      "invoices_workOrder.name: "someName"
     * }
     * this function converts the above examples to:
     *  {
     *      "name": "someName",
     *      "workOrder": {"ID": "someID", "name": "someName"}
     *  }
     */
    const convertedResults: Array<any> = [];
    if (Utils.isEmpty(results)) {
      return convertedResults;
    }
    for (const result of results) {
      const convertedResult = {};
      const expandedDotNotationResult = Utils.expandDotNotationToNestedObject(
        result
      );
      for (const [key, value] of Object.entries(expandedDotNotationResult)) {
        const splittenFieldName = key.split(`${moduleID}_`);
        const newKeyName =
          splittenFieldName.length > 1
            ? key.split(`${moduleID}_`)[1]
            : splittenFieldName[0];
        convertedResult[newKeyName] = value;
      }
      convertedResults.push(convertedResult);
    }
    return convertedResults;
  }
}
