import Utils from "@/utils/Utils";
import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import TableFilter from "../Models/TableFilter";
import {useModulesStore} from "../../Modules/Store/ModuleStore"

export default class TableViewHelper {

    static async getColumnDefFromTableView(tableFilter: TableFilter, moduleID: string, typeIsRelatedRecords?: boolean): Promise<ColDef[]> {
        const columns: ColDef[] = [];
        const checkList = []
        const modulesStore = useModulesStore();
        await modulesStore.initModules
        const fields = moduleID && modulesStore.modules[moduleID] ? modulesStore.modules[moduleID].fields : []

        if(tableFilter && tableFilter.columns) {
            for(const column of tableFilter?.columns) {
                if(!fields[column]) {
                    continue
                }
                const definedColumn: any = fields[column].getColumn(fields[column], moduleID, typeIsRelatedRecords)
                if(!Utils.isEmpty(definedColumn)) {
                    columns.push(definedColumn)
                    checkList.push(column)
                }
            }
        }
        for(const f in fields) {
            const field = fields[f]
            if(!checkList.includes(f) && field && !field.hidden) {
                if(!field) {
                    continue
                }
                const definedColumn: any = field.getColumn(field, moduleID, typeIsRelatedRecords)
                if(!Utils.isEmpty(definedColumn) && definedColumn !== null) {
                    definedColumn.hide = true
                    columns.push(definedColumn)
                }
            }
        }
        return columns
    }
}