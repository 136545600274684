
import store from "@/store";
import Utils from "@/utils/Utils";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component, Vue, Watch } from "vue-property-decorator";
import {useModulesStore} from "../../../Modules/Store/ModuleStore"

@Component({})
export default class DateTimeFieldEditor extends Vue {
    value = null;
    loaded = false;
    field = null;
    hasErrorOrEmpty = false;
    params: ICellRendererParams = null
    modulesStore = useModulesStore()
    dateTimeMask: string = store.state.dateTimeMask
    timeoutID = null;

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
        clearTimeout(this.timeoutID)
    }

    mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        const elements = document.getElementsByClassName("ag-popup-editor")
        this.timeoutID = setTimeout(() => {
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
        }, 1);
    }

    isValid() {
        if(this.hasErrorOrEmpty) {
            return false
        }
        return true
    }

    getValue() {
        if(!this.isValid() || !this.validDateTime()) {
            return this.params.value;
        }
        return this.value ? this.field.getUnformattedValue(this.value) : null
    }

    validDateTime() {
        if(this.value === '') {
            this.value = null
            return true
        }
        const dateTimeRegex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s([01]\d|2[0-3]):([0-5]\d)$/
        const dateRegex = /^(0[1-9]|[1-2]\d|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/
        if (dateRegex.test(this.value) && this.value.length === 10 || dateTimeRegex.test(this.value) && this.value.length === 16) {
            if (this.value && this.value.length === 10) this.value = this.value + ' 00:00';
            return true
        } else {
            if(this.value !== null) {
              const message: any = this.$t('common.messages.invalidDate')
              this.$q.notify({color: "negative", message: message})
            }
            this.value = null
            return false
        }
    }

    get fieldValue(): number {
        if (this.params) {
            this.field = this.modulesStore.getField(this.params.context.componentParent.getModuleID(), this.params.colDef['field'])
            if (this.params.value) {
                return this.field.getFormattedValue(this.params.value)
            }
        }
        return null;
    }

    @Watch('value') 
    valueHandler(){
        if(this.field.mandatory) {
            if(Utils.isEmpty(this.value)) {
                this.hasErrorOrEmpty = true
                return
            }
        }
        this.hasErrorOrEmpty = false
    }
}
