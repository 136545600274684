
import store from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import Dialog from "@/components/Parts/Dialog.vue";
import {useTablesStore} from "../../Store/TableStore"
import TableView from "../../Models/TableView";
import TableViewColumn from "../../Models/TableViewColumn";
import { ICellRendererParams } from "ag-grid-community";
import TableViewHelper from "../../Helpers/TableViewHelper";
import Utils from "@/utils/Utils";
import VueI18n from "@/i18n";
import useModulesStore from "@/components/Modules/Store/ModuleStore";
import { FieldType } from "@/components/Fields/Models/FieldType";
import sortList from "@/common/helpers/utilities";
  
@Component({
    components: {
        Dialog
    }
})
export default class TableViewPanel extends Vue {
    loaded = false;
    gridApi = null
    columnApi = null
    selectedView = null
    currentUser = null
    tableStore = null
    modulesStore = useModulesStore()
    currentView = null
    organizationColumns = null
    unsubTimeOutID = null
    showEditViewModal = false
    sharedView = false
    viewCreatedBy = false
    viewName = ''
    moduleID = ''
    sectionID = ''
    createNewTableViewSubHeader = ''
    section = null
    tableViews = []
    showCreateNewTableView = false
    showDeleteViewModal = false
    params: ICellRendererParams = null

    setup(props: any) {
        this.params = props.params
    }

    async mounted() {
        this.tableStore = useTablesStore()
        await this.modulesStore.initModules
        this.currentUser = store.state.currentUser
        this.gridApi = this.params.api
        this.columnApi = this.params.columnApi
        this.moduleID = this.params.context.componentParent.moduleID
        this.sectionID = this.params.context.componentParent.section_id
        this.section = this.params.context.componentParent.section
        const collectionID = this.params.context.componentParent.tableID
        this.tableViews = await this.tableStore.getTableViews(collectionID)
        this.tableViews.sort((a, b) => a.name.localeCompare(b.name))
        const defaultTableView = await this.getDefaultOrganizationView()
        this.tableViews.unshift(defaultTableView)
        if(store.state.settings.lastUsedTableViews) {
            if(Utils.isEmpty(store.state.settings.lastUsedTableViews[this.params.context.componentParent.tableIDForLastUsedView]) || store.state.settings.lastUsedTableViews[this.params.context.componentParent.tableIDForLastUsedView] == 'defaultView') {
                this.params.context.currentView = defaultTableView
                await this.loadView(defaultTableView, true)
            } else {
                const view = await this.tableStore.getTableViewByID(collectionID, store.state.settings.lastUsedTableViews[this.params.context.componentParent.tableIDForLastUsedView], this.params.context.componentParent.type)
                this.params.context.currentView = view
                await this.loadView(view, true)
            }
        } else {
            await this.loadView(defaultTableView, true)
        }
        this.loaded = true;
    }

    async getDefaultOrganizationView() {
        this.organizationColumns = await this.tableStore.organizationColumns;
        const moduleID = this.getModuleID()
        const array = this.organizationColumns[moduleID]
        const tableFilter: any = {
            columns: array,
            filter: {}
        }
        const columns = await TableViewHelper.getColumnDefFromTableView(tableFilter, moduleID)
        const visibleColumns = []
        for(const column of columns) {
            if(!column.hide) {
                delete column.filter
                visibleColumns.push(column)
            }
        }
        return {
            columns: visibleColumns,
            ID: 'defaultView',
            name: VueI18n.t('table.headers.defaultView'),
            createdBy: {ID: 'system', name: 'system'},
            defaultView: true,
            sharedView: false
        }
    }

    async openEditViewModal(view) {
        await this.loadView(view, false)
        this.showEditViewModal = true
        this.sharedView = view.sharedView ? view.sharedView : false
        this.viewCreatedBy = view.createdBy ? view.createdBy : this.currentUser
        this.viewName = view.name
        this.currentView = view
    }

    createNewTableView() {
        this.showCreateNewTableView = true
        this.viewCreatedBy = this.currentUser
        this.viewName = ''
        this.sharedView = false
    }

    async updateLastUsedView(view: any) {
        await this.tableStore.updateLastUsedView(view, this.params.context.componentParent.tableIDForLastUsedView)
    }

    async loadView(view, checkActivePage: boolean) {
        if(this.gridApi.destroyCalled) {
            return
        }
        await this.updateLastUsedView(view)
        const columns = await this.params.context.componentParent.getColumnDefinitions()
        this.selectedView = view
        this.currentView = view
        this.viewName = view.name
        this.sharedView = view.sharedView
        this.params.context.currentView = view
        const filterInstance = this.gridApi.getFilterModel();
        const definedColumns = []
        const columnInstance = []
        for(const column of view.columns) {
            if(column.values) {
                for(const index in column.values) {
                    const translationKey = `${this.getModuleID()}.dropDowns.${column.field}.${column.values[index]}`
                    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                        column.values[index] = store.state.fieldTranslations[translationKey]
                    }
                }
            }
            definedColumns.push(column.field)
            filterInstance[column.field] = column
            column.colId = column.field
            column.sort = column.sort ? column.sort : null
            column.width = column.actualWidth
            columnInstance.push(column)
        }
        const shownColumns = []
        const columnsToSort = []
        for(const column of columns) {
            column.hide = false
            if(!definedColumns.includes(column.field)) {
                column.hide = true
                columnsToSort.push(column)
                delete filterInstance[column.field]
            } else {
                shownColumns.push(column)
            }
        }
        const sortedColumns = sortList(columnsToSort, 'headerName')
        this.params.context.columns = [...shownColumns, ...sortedColumns]
        this.gridApi.setColumnDefs(columns)
        this.columnApi.applyColumnState({
            state: columnInstance,
            defaultState: { pinned: null },
        });
        this.gridApi.closeToolPanel()
        this.columnApi.moveColumns(definedColumns, 0)
        if(checkActivePage && this.checkCurrentPageExists()) {
            this.unsubTimeOutID = setTimeout(() => {
                this.gridApi.paginationGoToPage(this.tableStore.currentPage[this.params.context.componentParent.tableIDForLastUsedView])
            }, 500);
        }
        await this.params.context.componentParent.refreshData(true)
        this.gridApi.setFilterModel(filterInstance);
    }

    checkCurrentPageExists() {
        return this.tableStore && this.tableStore.currentPage && this.tableStore.currentPage[this.params.context.componentParent.tableIDForLastUsedView]
    }

    async checkSharedViewChanged() {
        return this.currentView && !this.currentView.sharedView && this.sharedView || !this.sharedView && this.currentView && this.currentView.sharedView
    }

    async onSaveNewTableView() {
        const columns: TableViewColumn[] = this.getColumns()
        const view: TableView = {
            columns: columns,
            name: this.viewName,
            createdBy: this.currentUser,
            sharedView: this.sharedView
        }
        const collectionID = this.params.context.componentParent.tableID
        if(this.sharedView) {
            await this.tableStore.saveSharedView(view, collectionID)
        } else {
            await this.tableStore.saveUserView(view, collectionID)
        }
        const message: any = VueI18n.t('table.messages.viewCreated')
        this.$q.notify({ message: message, color: 'positive'})
        await this.updateLastUsedView(view)
        this.tableViews = await this.tableStore.getTableViews(collectionID)
        this.tableViews.sort((a, b) => a.name.localeCompare(b.name))
        const defaultTableView = await this.getDefaultOrganizationView()
        this.tableViews.unshift(defaultTableView)
    }

    openDeleteModal() {
        this.showDeleteViewModal = true
    }

    async onDeleteTableView() {
        const collectionID = this.params.context.componentParent.tableID
        if(!this.currentView.sharedView) {
            await this.tableStore.deleteUserView(this.currentView, collectionID)
        } else {
            await this.tableStore.deleteSharedView(this.currentView, collectionID)
        }
        this.tableViews = await this.tableStore.getTableViews(collectionID)
        this.tableViews.sort((a, b) => a.name.localeCompare(b.name))
        const defaultTableView = await this.getDefaultOrganizationView()
        this.tableViews.unshift(defaultTableView)
        if(this.currentView.ID === this.selectedView.ID) await this.updateLastUsedView(defaultTableView)
    }

    async updateSelectedTableView() {
        const columns: TableViewColumn[] = this.getColumns()
        const view: TableView = {
            columns: columns,
            name: this.viewName,
            ID: this.currentView.ID,
            createdBy: this.currentUser,
            sharedView: this.sharedView
        }
        await this.updateLastUsedView(view)
        const collectionID = this.params.context.componentParent.tableID
        this.params.context.currentView = view
        if(this.sharedView) {
            if(this.checkSharedViewChanged()) {
                await this.tableStore.deleteUserView(view, collectionID)
            }
            await this.tableStore.saveSharedView(view, collectionID)
        } else {
            if(this.checkSharedViewChanged()) {
                await this.tableStore.deleteSharedView(view, collectionID)
            }
            await this.tableStore.saveUserView(view, collectionID)
        }
        const message: any = VueI18n.t('table.messages.updatedView')
        this.$q.notify({ message: message, color: 'positive'})
        this.tableViews = await this.tableStore.getTableViews(collectionID)
        this.tableViews.sort((a, b) => a.name.localeCompare(b.name))
        const defaultTableView = await this.getDefaultOrganizationView()
        this.tableViews.unshift(defaultTableView)
    }

    getColumns() {
      const filterInstance = this.gridApi.getFilterModel();
      const displayedColumns = this.gridApi.columnModel.displayedColumns
      const columnsWithFilter = []
      const columns: TableViewColumn[] = []
      for(const field in filterInstance) {
        const moduleID = this.getModuleID()
        const fieldConfig = this.modulesStore.getField(moduleID, field)
        if(fieldConfig.getFieldType() === FieldType.DROPDOWN) {
            for(const value in filterInstance[field].values) {
                filterInstance[field].values[value] = this.findKeyByValue(moduleID, field, filterInstance[field].values[value])
            }
        }
        columnsWithFilter.push(field)
      }
      for(const column of displayedColumns) {
          if(!columnsWithFilter.includes(column.colId)) {
              columns.push({
                  field: column.colId,
                  pinned: column.pinned,
                  actualWidth: column.actualWidth,
                  sort: column.sort ? column.sort : null
              })
          }
      }
      for(const filter in filterInstance) {
          for(const column in displayedColumns) {
              if(displayedColumns[column].colId == filter) {
                  if(displayedColumns[column].sort) filterInstance[filter].sort = displayedColumns[column].sort
                  filterInstance[filter].field = filter
                  columns.splice(Number(column), 0, filterInstance[filter])
              }
          }
      }
      return columns
    }

    getModuleID() {
        let moduleID = this.moduleID
        if(this.section && this.section.type  == "relatedRecords") {
            moduleID = this.section.relatedModule
        }
        if(this.section && this.section.type == "productLines") {
            moduleID = this.section.lineType
        }
        return moduleID
    }

    findKeyByValue(moduleID: string, fieldName: string, targetValue: any): string | null {
        const translationKey = `${moduleID}.dropDowns.${fieldName}`
        for(const index in store.state.fieldTranslations) {
            if(store.state.fieldTranslations[index] === targetValue && index.includes(translationKey)) {
                const splittedKeyName = index.split('.')
                return splittedKeyName[splittedKeyName.length - 1]
            }
        }
        return targetValue;
    }

    beforeDestroy() {
        try {
            clearTimeout(this.unsubTimeOutID)
        } catch {
            // Empty catch, because only try is not possible
        }
    }

    @Watch('params.context.saveCurrentView')
    saveCurrentViewHandler() {
        this.updateSelectedTableView()
        return;
    }
    
    @Watch('params.context.viewCreated')
    newViewHandler() {
        this.viewName = this.params.context.newView.viewName
        this.sharedView = this.params.context.newView.sharedView
        this.onSaveNewTableView()
        return;
    }
}
