import { useModulesStore } from '@/components/Modules/Store/ModuleStore';
import { IServerSideGetRowsParams, IServerSideGetRowsRequest } from "ag-grid-community";
import { ServerSideOperatorType } from "../Models/ServerSideOperatorType";
import AGGridHelper from "./AGGridHelper";
import ElasticHelper from "./ElasticHelper";
import DateUtils from "@/components/utils/DateUtils";
import { FieldType } from "@/components/Fields/Models/FieldType";
import store from '@/store';

export default class SQLHelper {
   static buildQueryFromAGGridFilter(params: IServerSideGetRowsParams, tenantID: string, moduleID: string, additionalFilters?: any): string{
        const convertedColumnNames = this.getConvertedColumnNames(params.columnApi, moduleID)
        const select = this.createSelectSQL(params.request, convertedColumnNames);
        const from = ' FROM ' + tenantID.toLowerCase();
        const where = this.createWhereSQL(params.request, moduleID, additionalFilters);
        const limit = this.createLimitSQL(params.request);

        const orderBy = this.createOrderBySQL(params.request, moduleID);
        const groupBy = this.createGroupBySQL(params.request);

        const SQL = select + from + where + groupBy + orderBy + limit;
        return SQL;
   }

   static getConvertedColumnNames(columnApi, moduleID: string): string[] {
    const activeColumnIDs = AGGridHelper.getActiveColumnIDs(columnApi)
    const modulesStore = useModulesStore()
    const mandatoryFields = modulesStore.getMandatoryFields(moduleID)
    for(const mandatoryField of mandatoryFields) {
        if(!activeColumnIDs.includes(mandatoryField.name)) {
            activeColumnIDs.push(mandatoryField.name)
        }
    }
    const convertedColumnNames = ElasticHelper.convertFieldNamesToElasticFieldNames(activeColumnIDs, moduleID)
    if(!convertedColumnNames.includes("ID")){
        convertedColumnNames.push("ID")
    }
    return convertedColumnNames
   }

   static buildCountQuery(params: IServerSideGetRowsParams, tenantID: string, moduleID: string, additionalFilters?: any){
    const where = this.createWhereSQL(params.request, moduleID, additionalFilters);
    return `SELECT COUNT("ID") AS count FROM ${tenantID.toLocaleLowerCase()}` + where;
   }
   static checkIfTwoQueriesAreTheSame(firstQuery: string, secondQuery: string): boolean {
    return firstQuery === secondQuery;
   }

   static buildAggregationQuery(fields: any, tenantID: string, moduleID: string, filterData: any, additionalFilter?: any): string {
    const queries = []
    for(const field of fields) {
        if(field.type === FieldType.CURRENCY || field.type === FieldType.NUMBER || field.type === FieldType.DECIMAL) {
            queries.push(`SUM(${moduleID}_${field.name}) as ${field.name}`)
        }
    }
    const request = {
        rowGroupCols: null,
        groupKeys: null,
        filterModel: filterData,
        startRow: null,
        endRow: null,
        valueCols: null,
        pivotCols: null,
        pivotMode: false,
        sortModel: null
    }
    const where = this.createWhereSQL(request, moduleID, additionalFilter)
    const SQL = `SELECT ${queries.join(', ')} FROM ${tenantID.toLowerCase()} ${where}`
    return SQL
   }

   static createSelectSQL(request: IServerSideGetRowsRequest, columnNames: Array<string>): string{
        const rowGroupCols = request.rowGroupCols;
        const valueCols = request.valueCols;
        const groupKeys = request.groupKeys;

        if (this.isDoingGrouping(rowGroupCols, groupKeys)) {
            const colsToSelect = [];

            const rowGroupCol = rowGroupCols[groupKeys.length];
            colsToSelect.push(rowGroupCol.field);

            valueCols.forEach(function (valueCol) {
                colsToSelect.push(valueCol.aggFunc + '(' + valueCol.field + ') as ' + valueCol.field);
            });

            return 'SELECT ' + colsToSelect.join(', ');
        }

        if(columnNames && columnNames.length) {
            return 'SELECT ' + columnNames.join(', ');
        }

        return 'SELECT *';
    }

    static createFilterSQL(moduleID: string, key, item) {
        const modulesStore = useModulesStore()
        const fieldName = key === "ID" ? "ID" : key.split('_')[1]
        const field: any = modulesStore.getField(moduleID, fieldName)
        switch (item.filterType) {
            case 'text':
                return this.createTextFilterSQL(key, item);
            case 'set':
                return this.createSetFilterSQL(key, item, moduleID);
            case 'number':
                if(field) {
                    if(field.type === 'decimal' || field.type === 'number') {
                        return this.createDecimalFilterSQL(key, item);
                    }
                }
                return this.createNumberFilterSQL(key, item);
            case 'date':
                return this.createDateFilterSQL(key, item);
            default:
                console.log('unknown filter type: ' + item.filterType);
        }
    }

    static createDecimalFilterSQL(key, item) {
        switch (item.type) {
            case 'equals':
                return '(' + key + ' >= ' + item.filter + ' and ' + key + ' <= ' + (item.filter) + ') ';
            case 'notEqual':
                return `${key} != ${item.filter} `
            case 'greaterThan':
                return `${key} > ${item.filter} `
            case 'greaterThanOrEqual':
                return `${key} >= ${item.filter} `
            case 'lessThan':
                return `${key} < ${item.filter} `
            case 'lessThanOrEqual':
                return `${key} <= ${item.filter} `
            case 'inRange':
                return '(' + key + ' > ' + item.filter + ' and ' + key + ' < ' + item.filterTo + ') ';
            case 'blank':
                return `${key} IS NULL `
            case 'notBlank':
                return `${key} IS NOT NULL `
            default:
                console.log('unknown number filter type: ' + item.type);
                return 'true';
        }
    }

    static createNumberFilterSQL(key, item) {
        const value = item.filter*10000
        switch (item.type) {
            case 'equals':
                return '(' + key + ' >= ' + value + ' and ' + key + ' <= ' + Number((value) + 99) + ') ';
            case 'notEqual':
                return `${key} != ${value} `
            case 'greaterThan':
                return `${key} > ${value} `
            case 'greaterThanOrEqual':
                return `${key} >= ${value} `
            case 'lessThan':
                return `${key} < ${value} `
            case 'lessThanOrEqual':
                return `${key} <= ${value} `
            case 'inRange':
                return '(' + key + ' > ' + value + ' and ' + key + ' < ' + item.filterTo * 10000 + ') ';
            case 'blank':
                return `${key} IS NULL `
            case 'notBlank':
                return `${key} IS NOT NULL `
            default:
                console.log('unknown number filter type: ' + item.type);
                return 'true';
        }
    }

    static createBooleanFilterSQL(key, item) {
        const dropdownFilters = []
        if(item.values.includes('true') && item.values.includes(null)){
            dropdownFilters.push(key + '!= false');
        } 
        if(item.values.includes('false') && item.values.includes(null)){
            dropdownFilters.push(key + '!= true');
        }
        if(item.values.includes('false') && item.values.includes('true')){
            dropdownFilters.push(key + '= false');
            dropdownFilters.push(key + '= true');
        }
        if(item.values.length === 1 && item.values[0] === null){
            dropdownFilters.push(key + ' IS NULL');
        }
        if(item.values.length === 1 && item.values[0] === 'false'){
            dropdownFilters.push(key + '= false');
        }
        if(item.values.length === 1 && item.values[0] === 'true'){
            dropdownFilters.push(key + '= true');
        }
        if(item.values.length === 0){
            dropdownFilters.push(key + ' IS NULL');
        }
        const filterString = dropdownFilters.join(' or ')
        return `(${filterString})`
    }
    static createSetFilterSQL(key, item, moduleID: string) {
        const modulesStore = useModulesStore()
        const fieldName = key.replace(`${moduleID}_`, "")
        const field: any = modulesStore.getField(moduleID, fieldName)
        const dropdownFilters = []
        if(field && field.type === FieldType.CHECKBOX){
            return this.createBooleanFilterSQL(key, item)
        }
        if((!item.values || item.values.length === 0) || (item.values && item.values.length === 1 && item.values[0] === null)) {
            return `(${key} IS NULL OR ${key} = '') `
        }
        let values = ""
        for(const value of item.values) {
            if(value !== null) {
                let newValue = value
                const keyName = this.findKeyByValue(moduleID, fieldName, value)
                if(keyName) {
                    const splittedKeyName = keyName.split('.')
                    newValue = splittedKeyName[splittedKeyName.length - 1]
                }
                if(newValue.includes("'")) {
                    newValue = value.replace("'", "''")
                }
                dropdownFilters.push(key + " = '" + newValue + "'")
                values += `'${newValue}', `
            }
        }
        values = values.slice(0, -2);
        let string = `${key} IN(${values})`
        if (item.values.includes(null)) string = `(${key} IN(${values}) OR ${key} IS NULL)`
        return string
    }

    static findKeyByValue(moduleID: string, fieldName: string, targetValue: any): string | null {
        const translationKey = `${moduleID}.dropDowns.${fieldName}`
        for(const index in store.state.fieldTranslations) {
            if(store.state.fieldTranslations[index] === targetValue && index.includes(translationKey)) {
                return index
            }
        }
        return null;
    }

    static createTextFilterSQL(key, item) {
        let filterText: string;
        if(item && item.filter){
            filterText = item.filter.toString().toLowerCase();
        }
        switch (item.type) {
            case 'equals':
                return `LCASE("${key}") = '${filterText}'`;      
            case 'notEqual':
                return `(LCASE("${key}") != '${filterText}' OR ${key} IS NULL)`;
            case 'contains':
                return `LCASE("${key}") like '%${filterText}%'`;
            case 'notContains':
                return `(LCASE("${key}") not like '%${filterText}%' OR ${key} IS NULL)`;
            case 'startsWith':
                return `LCASE("${key}") like '${filterText}%'`;
            case 'endsWith':
                return `LCASE("${key}") like '%${filterText}'`;
            case 'blank':
                return `(${key} IS NULL OR ${key} = '') `
            case 'notBlank':
                return `${key} IS NOT NULL AND ${key}<>'' `
            default:
                console.log('unknown text filter type: ' + item.type);
                return 'true';
        }
    }

    static createDateFilterSQL(key, item) {
        const toDate = item.dateFrom ? item.dateFrom.replace('00:00:00', '23:59:59') : null
        switch (item.type) {
            case 'equals':
                return '("' + key + '"' + ' >= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom) + ' and "' + key + '"' + ' <= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(toDate) + ')';
            case 'notEqual':
                return '("' + key + '"' + ' <= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom) + ' or "' + key + '"' + ' >= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(toDate) + ')';
            case 'greaterThan':
                return '"' + key + '"' + ' > ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom);
            case 'greaterThanOrEqual':
                return '"' + key + '"' + ' >= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom);
            case 'lessThan':
                return '"' + key + '"' +' < ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom);
            case 'lessThanOrEqual':
                return '"' + key + '"' + ' <= ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom);
            case 'inRange':
                return '("' + key + '"' + ' > ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateFrom) + ' and "' + key + '"' + ' < ' + DateUtils.convertTimeStringToSecondsSinceEpoch(item.dateTo) + ')';
            case 'blank':
                return `"${key}" IS NULL `
            case 'notBlank':
                return `"${key}" IS NOT NULL `
            default:
                console.log('unknown number filter type: ' + item.type);
                return 'true';
        }
    }

    static getSQLWhereOperator(operator: string) {
        switch(operator) {
            case ServerSideOperatorType.ISEQUAL:
                return '=';
            case ServerSideOperatorType.ISNOTEQUAL:
                return '!=';
            case ServerSideOperatorType.GREATERTHAN:
                return '>';
            case ServerSideOperatorType.GREATERTHANOREQUAL:
                return '>=';
            case ServerSideOperatorType.SMALLERTHAN:
                return '<';
            case ServerSideOperatorType.SMALLERTHANOREQUAL:
                return '<=';
            default:
                throw new Error(`Error while getting operator for where filter, opertor of type '${operator}' does not exists`);
        }
    }

    static additionalFiltersToWhereParts(additionalFilters: any[]) {
        const SQLWhereFilters = []
        for(const additionalFilter of additionalFilters) {
            const operator = this.getSQLWhereOperator(additionalFilter.operator);
            SQLWhereFilters.push(`${additionalFilter.moduleID}_${additionalFilter.field}${operator}'${additionalFilter.value}'`);
        }
        return SQLWhereFilters
    } 

    static createWhereSQL(request: IServerSideGetRowsRequest, moduleID: string, additionalFilters?: any[]): string{
        const rowGroupCols = request.rowGroupCols;
        const groupKeys = request.groupKeys;
        const filterModel = request.filterModel;
        const whereParts = [`_module='${moduleID}'`];

        if(additionalFilters && additionalFilters.length) {
            const additionalWhereParts = this.additionalFiltersToWhereParts(additionalFilters)
            for(const additionalWherePart of additionalWhereParts) {
                whereParts.push(additionalWherePart)
            }
        }        

        if (groupKeys && groupKeys.length > 0) {
            groupKeys.forEach((key, index) => {
                const colName = rowGroupCols[index].field;
                whereParts.push(colName + ' = "' + key + '"')
            });
        }

        if (filterModel) {
            const keySet = Object.keys(filterModel);
            keySet.forEach((key) => {
                const item = filterModel[key];
                const elasticFieldName = ElasticHelper.fieldNameToElasticSearchFieldName(key, moduleID)
                if(item && item.filterType === 'set') {
                    whereParts.push(this.createFilterSQL(moduleID, elasticFieldName, item));
                } else {
                    if(item && !Number.isNaN(item.filter)) {
                        whereParts.push(this.createFilterSQL(moduleID, elasticFieldName, item));
                    }
                }
            });
        }

        if (whereParts.length > 0) {
            return ' where ' + whereParts.join(' and ');
        } else {
            return '';
        }
    }

    static createGroupBySQL(request: IServerSideGetRowsRequest): string{
        const rowGroupCols = request.rowGroupCols;
        const groupKeys = request.groupKeys;

        if (this.isDoingGrouping(rowGroupCols, groupKeys)) {
            const colsToGroupBy = [];

            const rowGroupCol = rowGroupCols[groupKeys.length];
            colsToGroupBy.push(rowGroupCol.field);

            return ' group by ' + colsToGroupBy.join(', ');
        } else {
            // select all columns
            return '';
        }
    }

    static createOrderBySQL(request: IServerSideGetRowsRequest, moduleID: string): string{
        const rowGroupCols = request.rowGroupCols;
        const groupKeys = request.groupKeys;
        const sortModel = request.sortModel;

        const grouping = this.isDoingGrouping(rowGroupCols, groupKeys);

        const sortParts = [];
        if (sortModel) {

            const groupColIds =
                rowGroupCols.map(groupCol => groupCol.id)
                    .slice(0, groupKeys.length + 1);

            sortModel.forEach(function (item) {
                if (grouping && groupColIds.indexOf(item.colId) < 0) {
                    // ignore
                } else {
                    sortParts.push('"' + ElasticHelper.fieldNameToElasticSearchFieldName(item.colId, moduleID) + '"'+ ' ' + item.sort);
                }
            });
        }

        if (sortParts.length > 0) {
            return ' order by ' + sortParts.join(', ');
        } else {
            return ` order by "${moduleID}_changedAt.seconds" desc`;
        }
    }

    static isDoingGrouping(rowGroupCols, groupKeys) {
        // we are not doing grouping if at the lowest level. we are at the lowest level
        // if we are grouping by more columns than we have keys for (that means the user
        // has not expanded a lowest level group, OR we are not grouping at all).
        return rowGroupCols.length > groupKeys.length;
    }

    static createLimitSQL(request: IServerSideGetRowsRequest): string{
        if(request.startRow && request.endRow) {
            // const startRow = request.startRow;
            // const endRow = request.endRow;
            // const pageSize = endRow - startRow;
            // return ' limit ' + (pageSize + 1)
            return ''
        }
        return ''
    }

    static getRowCount(request, results) {
        if (results === null || results === undefined || results.length === 0) {
            return null;
        }
        const currentLastRow = request.startRow + results.length;
        return currentLastRow <= request.endRow ? currentLastRow : -1;
    }

    static cutResultsToPageSize(request, results) {
        const pageSize = request.endRow - request.startRow;
        if (results && results.length > pageSize) {
            return results.splice(0, pageSize);
        } else {
            return results;
        }
    }
}