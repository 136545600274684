import VueI18n from "@/i18n"

export default class TableTranslationsHelper {
    getTranslations() {
        return {
            // Set Filter
            selectAll: VueI18n.t("table.agGrid.selectAll"),
            selectAllSearchResults: VueI18n.t("table.agGrid.selectAllSearchResults"),
            searchOoo: VueI18n.t("table.agGrid.searchOoo"),
            blanks: VueI18n.t("table.agGrid.blanks"),
            noMatches: VueI18n.t("table.agGrid.noMatches"),
        
            // Number Filter & Text Filter
            filterOoo: VueI18n.t("table.agGrid.filterOoo"),
            equals: VueI18n.t("table.agGrid.equals"),
            notEqual: VueI18n.t("table.agGrid.notEqual"),
            blank: VueI18n.t("table.agGrid.blank"),
            notBlank: VueI18n.t("table.agGrid.notBlank"),
            empty: VueI18n.t("table.agGrid.empty"),
        
            // Number Filter
            lessThan: VueI18n.t("table.agGrid.lessThan"),
            greaterThan: VueI18n.t("table.agGrid.greaterThan"),
            lessThanOrEqual: VueI18n.t("table.agGrid.lessThanOrEqual"),
            greaterThanOrEqual: VueI18n.t("table.agGrid.greaterThanOrEqual"),
            inRange: VueI18n.t("table.agGrid.inRange"),
            inRangeStart: VueI18n.t("table.agGrid.inRangeStart"),
            inRangeEnd: VueI18n.t("table.agGrid.inRangeEnd"),
        
            // Text Filter
            contains: VueI18n.t("table.agGrid.contains"),
            notContains: VueI18n.t("table.agGrid.notContains"),
            startsWith: VueI18n.t("table.agGrid.startsWith"),
            endsWith: VueI18n.t("table.agGrid.endsWith"),
        
            // Date Filter
            dateFormatOoo: "dd-mm-yyyy HH:ii",
        
            // Filter Conditions
            andCondition: VueI18n.t("table.agGrid.andCondition"),
            orCondition: VueI18n.t("table.agGrid.orCondition"),
        
            // Filter Buttons
            applyFilter: VueI18n.t("table.agGrid.applyFilter"),
            resetFilter: VueI18n.t("table.agGrid.resetFilter"),
            clearFilter: VueI18n.t("table.agGrid.clearFilter"),
            cancelFilter: VueI18n.t("table.agGrid.cancelFilter"),
        
            // Filter Titles
            textFilter: VueI18n.t("table.agGrid.textFilter"),
            numberFilter: VueI18n.t("table.agGrid.numberFilter"),
            dateFilter: VueI18n.t("table.agGrid.dateFilter"),
            setFilter: VueI18n.t("table.agGrid.setFilter"),
        
            // Group Column Filter
            groupFilterSelect: VueI18n.t("table.agGrid.groupFilterSelect"),
        
            // Side Bar
            columns: VueI18n.t("table.agGrid.columns"),
            filters: VueI18n.t("table.agGrid.filters"),
        
            // columns tool panel
            pivotMode: "Pivot mode",
            groups: VueI18n.t("table.agGrid.groups"),
            rowGroupColumnsEmptyMessage: "Drag here to set row groups",
            values: VueI18n.t("table.agGrid.values"),
            valueColumnsEmptyMessage: VueI18n.t("table.agGrid.valueColumnsEmptyMessage"),
            pivots: VueI18n.t("table.agGrid.pivots"),
            pivotColumnsEmptyMessage: VueI18n.t("table.agGrid.pivotColumnsEmptyMessage"),
        
            // Header of the Default Group Column
            group: VueI18n.t("table.agGrid.group"),
        
            // Row Drag
            rowDragRow: VueI18n.t("table.agGrid.rowDragRow"),
            rowDragRows: VueI18n.t("table.agGrid.rowDragRows"),
        
            // Other
            loadingOoo: VueI18n.t("table.agGrid.loadingOoo"),
            loadingError: VueI18n.t("table.agGrid.loadingError"),
            noRowsToShow: VueI18n.t("table.agGrid.noRowsToShow"),
            enabled: VueI18n.t("table.agGrid.enabled"),
        
            // Menu
            pinColumn: VueI18n.t("table.agGrid.pinColumn"),
            pinLeft: VueI18n.t("table.agGrid.pinLeft"),
            pinRight: VueI18n.t("table.agGrid.pinRight"),
            noPin: VueI18n.t("table.agGrid.noPin"),
            valueAggregation: VueI18n.t("table.agGrid.valueAggregation"),
            noAggregation: VueI18n.t("table.agGrid.noAggregation"),
            autosizeThiscolumn: VueI18n.t("table.agGrid.autosizeThiscolumn"),
            autosizeAllColumns: VueI18n.t("table.agGrid.autosizeAllColumns"),
            groupBy: VueI18n.t("table.agGrid.groupBy"),
            ungroupBy: VueI18n.t("table.agGrid.ungroupBy"),
            addToValues: VueI18n.t("table.agGrid.addToValues"),
            removeFromValues: VueI18n.t("table.agGrid.removeFromValues"),
            addToLabels: VueI18n.t("table.agGrid.addToLabels"),
            removeFromLabels: VueI18n.t("table.agGrid.removeFromLabels"),
            resetColumns: VueI18n.t("table.agGrid.resetColumns"),
            expandAll: VueI18n.t("table.agGrid.expandAll"),
            collapseAll: VueI18n.t("table.agGrid.collapseAll"),
            copy: VueI18n.t("table.agGrid.copy"),
            ctrlC: "Ctrl+C",
            copyWithHeaders: VueI18n.t("table.agGrid.copyWithHeaders"),
            copyWithGroupHeaders: VueI18n.t("table.agGrid.copyWithGroupHeaders"),
            cut: VueI18n.t("table.agGrid.cut"),
            paste: VueI18n.t("table.agGrid.paste"),
            ctrlV: "Ctrl+V",
            export: VueI18n.t("table.agGrid.export"),
            csvExport: VueI18n.t("table.agGrid.csvExport"),
            excelExport: VueI18n.t("table.agGrid.excelExport"),
        
            // Enterprise Menu Aggregation and Status Bar
            sum: VueI18n.t("table.agGrid.sum"),
            first: VueI18n.t("table.agGrid.first"),
            last: VueI18n.t("table.agGrid.last"),
            min: VueI18n.t("table.agGrid.min"),
            max: VueI18n.t("table.agGrid.max"),
            none: VueI18n.t("table.agGrid.none"),
            count: VueI18n.t("table.agGrid.count"),
            avg: VueI18n.t("table.agGrid.avg"),
            filteredRows: VueI18n.t("table.agGrid.filteredRows"),
            selectedRows: VueI18n.t("table.agGrid.selectedRows"),
            totalRows: VueI18n.t("table.agGrid.totalRows"),
            totalAndFilteredRows: VueI18n.t("table.agGrid.totalAndFilteredRows"),
            more: VueI18n.t("table.agGrid.more"),
            to: VueI18n.t("table.agGrid.to"),
            of: VueI18n.t("table.agGrid.of"),
            page: VueI18n.t("table.agGrid.page"),
            nextPage: VueI18n.t("table.agGrid.nextPage"),
            lastPage: VueI18n.t("table.agGrid.lastPage"),
            firstPage: VueI18n.t("table.agGrid.firstPage"),
            previousPage: VueI18n.t("table.agGrid.previousPage"),
        
            // Pivoting
            pivotColumnGroupTotals: VueI18n.t("table.agGrid.pivotColumnGroupTotals"),
        
            // Enterprise Menu (Charts)
            pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
            pivotChart: "Pivot Chart",
            chartRange: "Chart Range",
        
            columnChart: "Column",
            groupedColumn: "Grouped",
            stackedColumn: "Stacked",
            normalizedColumn: "100% Stacked",
        
            barChart: "Bar",
            groupedBar: "Grouped",
            stackedBar: "Stacked",
            normalizedBar: "100% Stacked",
        
            pieChart: "Pie",
            pie: "Pie",
            doughnut: "Doughnut",
        
            line: "Line",
        
            xyChart: "X Y (Scatter)",
            scatter: "Scatter",
            bubble: "Bubble",
        
            areaChart: "Area",
            area: "Area",
            stackedArea: "Stacked",
            normalizedArea: "100% Stacked",
        
            histogramChart: "Histogram",
            histogramFrequency: "Frequency",
        
            combinationChart: "Combination",
            columnLineCombo: "Column & Line",
            AreaColumnCombo: "Area & Column",
        
            // Charts
            pivotChartTitle: "Pivot Chart",
            rangeChartTitle: "Range Chart",
            settings: "Settings",
            data: "Data",
            format: "Format",
            categories: "Categories",
            defaultCategory: "(None)",
            series: "Series",
            xyValues: "X Y Values",
            paired: "Paired Mode",
            axis: "Axis",
            navigator: "Navigator",
            color: "Color",
            thickness: "Thickness",
            xType: "X Type",
            automatic: "Automatic",
            category: "Category",
            number: "Number",
            time: "Time",
            autoRotate: "Auto Rotate",
            xRotation: "X Rotation",
            yRotation: "Y Rotation",
            ticks: "Ticks",
            width: "Width",
            height: "Height",
            length: "Length",
            padding: "Padding",
            spacing: "Spacing",
            chart: "Chart",
            title: "Title",
            titlePlaceholder: "Chart title - double click to edit",
            background: "Background",
            font: "Font",
            top: "Top",
            right: "Right",
            bottom: "Bottom",
            left: "Left",
            labels: "Labels",
            size: "Size",
            minSize: "Minimum Size",
            maxSize: "Maximum Size",
            legend: "Legend",
            position: "Position",
            markerSize: "Marker Size",
            markerStroke: "Marker Stroke",
            markerPadding: "Marker Padding",
            itemSpacing: "Item Spacing",
            itemPaddingX: "Item Padding X",
            itemPaddingY: "Item Padding Y",
            layoutHorizontalSpacing: "Horizontal Spacing",
            layoutVerticalSpacing: "Vertical Spacing",
            strokeWidth: "Stroke Width",
            lineDash: "Line Dash",
            offset: "Offset",
            offsets: "Offsets",
            tooltips: "Tooltips",
            callout: "Callout",
            markers: "Markers",
            shadow: "Shadow",
            blur: "Blur",
            xOffset: "X Offset",
            yOffset: "Y Offset",
            lineWidth: "Line Width",
            normal: "Normal",
            bold: "Bold",
            italic: "Italic",
            boldItalic: "Bold Italic",
            predefined: "Predefined",
            fillOpacity: "Fill Opacity",
            strokeOpacity: "Line Opacity",
            histogramBinCount: "Bin count",
            columnGroup: "Column",
            barGroup: "Bar",
            pieGroup: "Pie",
            lineGroup: "Line",
            scatterGroup: "X Y (Scatter)",
            areaGroup: "Area",
            histogramGroup: "Histogram",
            combinationGroup: "Combination",
            groupedColumnTooltip: "Grouped",
            stackedColumnTooltip: "Stacked",
            normalizedColumnTooltip: "100% Stacked",
            groupedBarTooltip: "Grouped",
            stackedBarTooltip: "Stacked",
            normalizedBarTooltip: "100% Stacked",
            pieTooltip: "Pie",
            doughnutTooltip: "Doughnut",
            lineTooltip: "Line",
            groupedAreaTooltip: "Area",
            stackedAreaTooltip: "Stacked",
            normalizedAreaTooltip: "100% Stacked",
            scatterTooltip: "Scatter",
            bubbleTooltip: "Bubble",
            histogramTooltip: "Histogram",
            columnLineComboTooltip: "Column & Line",
            areaColumnComboTooltip: "Area & Column",
            customComboTooltip: "Custom Combination",
            noDataToChart: "No data available to be charted.",
            pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
            chartSettingsToolbarTooltip: "Menu",
            chartLinkToolbarTooltip: "Linked to Grid",
            chartUnlinkToolbarTooltip: "Unlinked from Grid",
            chartDownloadToolbarTooltip: "Download Chart",
            seriesChartType: "Series Chart Type",
            seriesType: "Series Type",
            secondaryAxis: "Secondary Axis",
        
            // ARIA
            ariaChecked: "checked",
            ariaColumn: "Column",
            ariaColumnGroup: "Column Group",
            ariaColumnList: "Column List",
            ariaColumnSelectAll: "Toggle Select All Columns",
            ariaDateFilterInput: "Date Filter Input",
            ariaDefaultListName: "List",
            ariaFilterColumnsInput: "Filter Columns Input",
            ariaFilterFromValue: "Filter from value",
            ariaFilterInput: "Filter Input",
            ariaFilterList: "Filter List",
            ariaFilterToValue: "Filter to value",
            ariaFilterValue: "Filter Value",
            ariaFilteringOperator: "Filtering Operator",
            ariaHidden: "hidden",
            ariaIndeterminate:"indeterminate",
            ariaInputEditor: "Input Editor",
            ariaMenuColumn: "Press CTRL ENTER to open column menu.",
            ariaRowDeselect: "Press SPACE to deselect this row",
            ariaRowSelectAll: "Press Space to toggle all rows selection",
            ariaRowToggleSelection: "Press Space to toggle row selection",
            ariaRowSelect: "Press SPACE to select this row",
            ariaSearch: "Search",
            ariaSortableColumn: "Press ENTER to sort",
            ariaToggleVisibility: "Press SPACE to toggle visibility",
            ariaUnchecked: "unchecked",
            ariaVisible: "visible",
            ariaSearchFilterValues: "Search filter values",
        
            // ARIA Labels for Drop Zones
        
            ariaRowGroupDropZonePanelLabel: "Row Groups",
            ariaValuesDropZonePanelLabel: "Values",
            ariaPivotDropZonePanelLabel: "Column Labels",
            ariaDropZoneColumnComponentDescription: "Press DELETE to remove",
            ariaDropZoneColumnValueItemDescription: "Press ENTER to change the aggregation type",
            ariaDropZoneColumnGroupItemDescription: "Press ENTER to sort",
            // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
            ariaDropZoneColumnComponentAggFuncSeperator: " of ",
            ariaDropZoneColumnComponentSortAscending: "ascending",
            ariaDropZoneColumnComponentSortDescending: "descending",
        
            // ARIA Labels for Dialogs
            ariaLabelColumnMenu: "Column Menu",
            ariaLabelCellEditor: "Cell Editor",
            ariaLabelDialog: "Dialog",
            ariaLabelSelectField: "Select Field",
            ariaLabelTooltip: "Tooltip",
            ariaLabelContextMenu: "Context Menu",
            ariaLabelSubMenu: "SubMenu",
            ariaLabelAggregationFunction: "Aggregation Function",
        
            // Number Format (Status Bar, Pagination Panel)
            thousandSeparator: ".",
            decimalSeparator: ","
        
        }
    }
}
