
import Utils from "@/utils/Utils";
import { Component, Vue, Watch } from "vue-property-decorator";
import {useModulesStore} from "../../../Modules/Store/ModuleStore"
import debounce from "lodash/debounce";
import ListValues from "@/components/Mixin/ListValues";

@Component({})
export default class RelatedFieldEditor extends Vue {
    value = null;
    loaded = false;
    params = null
    field = null;
    timeoutID = null;
    hasErrorOrEmpty = false;
    modulesStore = useModulesStore()
    relatedOptions: any = [];
    debouncedFilter = debounce(this.filter, 250)

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
      clearTimeout(this.timeoutID)
    }

    async mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        this.relatedOptions = await this.loadRelatedFieldQueryOptions()
        const elements = document.getElementsByClassName("ag-popup-editor")
        const quasarSelects = document.getElementsByClassName("quasar-select")
        this.timeoutID = setTimeout(() => {
            for(const select of quasarSelects) {
                select['__vue__'].showPopup()
            }
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
            this.setupKeyListeners();
        }, 1);
    }

    setupKeyListeners() {
      const app = this
      const element = document.getElementById('related-select')
      if(!element) return
      element.addEventListener('keyup', function(event) {
          if (event.key === 'Enter') {
              app.params.stopEditing()
          }
      });
    }

    isValid() {
      if(this.hasErrorOrEmpty) {
          return false
      }
      if(Utils.isEmpty(this.value)) {
        return false
      }
      return true
    }

    getValue() {
      if(!this.isValid()) {
        return this.params.value;
      }
      return this.modulesStore.modules[this.params.context.componentParent.getModuleID()].fields[this.params.colDef['field']].getUnformattedValue(this.value)
    }

    get fieldValue(): number {
        this.field = this.modulesStore.getField(this.params.context.componentParent.getModuleID(), this.params.colDef['field'])
        if (this.params) {
            if (this.params.value) {
              return this.params.value
            }
        }
        return null;
    }

    filterAutocomplete(val, update) {
      val = val.replace('-', ' ')
      this.debouncedFilter(val, update)
    }

     async filter(val, update) {
      const filteredOptions = await this.loadRelatedFieldQueryOptions(val);
      filteredOptions.forEach(e => {
        if (this.field) {
          if (Object.prototype.hasOwnProperty.call(this.field,'primaryResultField')) {
            e.optionsRow1 = ListValues.transformOneByModule(e[this.field.primaryResultField], this.params.context.componentParent.getModuleID(), this.field.primaryResultField);
          }
          if (Object.prototype.hasOwnProperty.call(this.field,'secondaryResultField')) {
            e.optionsRow2 = ListValues.transformOneByModule(e[this.field.secondaryResultField], this.params.context.componentParent.getModuleID(), this.field.secondaryResultField);
          }
        }
      })
      
      update(() => {
        this.relatedOptions = Object.freeze(filteredOptions);
      });
    }

    async loadRelatedFieldQueryOptions(searchValue?: string) {
        const additionalField = [];
        additionalField.push('locked')
        const field: any = this.modulesStore.modules[this.params.context.componentParent.getModuleID()].fields[this.params.colDef['field']]
        if(field.primaryResultField) {
          additionalField.push(field.primaryResultField)
        }
        if(field.secondaryResultField) {
          additionalField.push(field.secondaryResultField)
        }
        let query: any = [{field: 'name'}]
        if(!Utils.isEmpty(searchValue)) {
            query = [{field: 'name', value: searchValue}]
        }
        if(field && field.primaryResultField) {
          query.push({field: field.primaryResultField, value: searchValue})
        }
        if(field && field.secondaryResultField) {
          query.push({field: field.secondaryResultField, value: searchValue})
        }
        const param = {
            query: query,
            relatedQuery: field.query,
            module: field.relatedModule,
            size: 100,
            additionalField: additionalField
        };
        const res = await this.$store.dispatch('query/queryRelatedFieldOptions', param).then((response) => {
            return response.docs
        }).catch((error) => {
            console.log('error while fetching related records: ', error);
            return []
        })
        return res
      }

    async executeRelatedFieldCopy() {
      if(!this.field.relatedFieldCopy) {
        return null;
      }
      const relatedFieldCopyData = await this.field.executeRelatedFieldCopy(this.value, this.field.relatedModule);
      if (Object.keys(relatedFieldCopyData).length) {
        for (const key in relatedFieldCopyData) {
          this.params.data[key] = relatedFieldCopyData[key];
        }
      }
    }

    @Watch('value') 
    valueHandler(){
        if(this.field.mandatory) {
            if(Utils.isEmpty(this.value)) {
                this.hasErrorOrEmpty = true
                return
            }
        }
        this.hasErrorOrEmpty = false
    }
}
