
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class CheckboxFieldEditor extends Vue {
    value = null;
    loaded = false;
    params = null;
    timeoutID = null;

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
        clearTimeout(this.timeoutID)
    }

    mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        const elements = document.getElementsByClassName("ag-popup-editor")
        this.timeoutID = setTimeout(() => {
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
            this.setupKeyListeners()
        }, 10);
    }

    setupKeyListeners() {
        const app = this
        const element = document.getElementById('checkbox-editor')
        element.addEventListener('keyup', function(event) {
            if (event.key === 'Enter') {
                app.params.stopEditing()
            }
        });
    }

    getValue() {
        return this.value
    }

    get fieldValue(): boolean {
        if (this.params) {
            if (this.params.value === true) {
                return true;
            } else if (this.params.value === false || this.params.value === undefined || this.params.value === null) {
                return false;
            }
        }
        return false;
    }
}
