import VueI18n from "@/i18n";


export const TOOLPANEL = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: '',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 180,
                maxWidth: 400,
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressColumnFilter: false,
                    suppressColumnSelectAll: false,
                    suppressColumnExpandAll: false,
                },
                width: 300
            },
            {
                id: 'tableViewPanel',
                labelDefault: ``,
                minWidth: 300,
                maxWidth: 300,
                width: 300,
                labelKey: 'tableViewPanel',
                iconKey: 'tableViewPanel',
                toolPanel: 'TableViewPanel',
            },
        ],
        position: 'left',
        defaultToolPanel: ''
    };