
import Utils from "@/utils/Utils";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component, Vue, Watch } from "vue-property-decorator";
import {useModulesStore} from "../../../Modules/Store/ModuleStore"

@Component({})
export default class DecimalFieldEditor extends Vue {
    value = null;
    loaded = false;
    hasErrorOrEmpty = false;
    params: ICellRendererParams = null
    field = null
    timeoutID = null;
    moduleID = null
    modulesStore = useModulesStore()
    format = {
        decimal: ',',
        thousands: '.',
        precision: 2,
        suffix: '',
        masked: false
    }

    setup(props: any) {
        this.params = props.params
    }

    beforeDestroy() {
        clearTimeout(this.timeoutID)
    }

    mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
        const elements = document.getElementsByClassName("ag-popup-editor")
        this.timeoutID = setTimeout(() => {
            for(const element of elements) {
                element['style'].width = this.params.column['actualWidth'] + 'px';
            }
        }, 1);
    }

    isValid() {
        if (this.field.min && this.value < Number(this.field.min)) {
            return false
        }
        if (this.field.max && this.value > Number(this.field.max)) {
            return false
        }
        if(this.hasErrorOrEmpty) {
            return false
        }
        return true
    }

    getValue() {
        if(!this.isValid()) {
            return this.params.value;
        }
        if(typeof this.value == 'string' && Utils.isEmpty(this.value)) {
            return null
        }
        return this.modulesStore.modules[this.moduleID].fields[this.field.name].getUnformattedValue(this.value)
    }

    get fieldValue(): number {
        this.moduleID = this.params.context.componentParent.getModuleID()
        this.field = this.modulesStore.getField(this.moduleID, this.params.colDef['field'])
        if (this.params) {
            if (this.params.value) {
                return this.modulesStore.modules[this.moduleID].fields[this.field.name].getFormattedValue(this.params.value)
            }
        }
        return null;
    }

    @Watch('value') 
    valueHandler(){
        if(this.field.mandatory) {
            if(Utils.isEmpty(this.value)) {
                this.hasErrorOrEmpty = true
                return
            }
        }
        this.hasErrorOrEmpty = false
    }
}
