
import { Vue, Prop, Component, Emit } from "vue-property-decorator";
import {useModulesStore} from "../../Modules/Store/ModuleStore"
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import draggable from 'vuedraggable';
import db from "@/db";
import store from "@/store";
import sortList from "@/common/helpers/utilities"
import Utils from "@/utils/Utils";
import { FieldType } from "@/components/Fields/Models/FieldType";

@Component({
    components: {
      draggable
    }
})

export default class ModuleInfoCard extends Vue {
    @Prop() moduleID: string;
    @Prop() recordID: string;
    @Prop() dynTitle: string;
    @Prop() formData: any;
    @Prop() moduleInfoCardShow: boolean;
    @Prop() changeConfig: boolean;
    @Prop() moduleListShown: boolean;
    moduleStore = useModulesStore();
    infoFields = []
    selectedInfoFields = []
    module = null;
    moduleSingularName = '';
    relatedRecordData = null;
    orderInfoEditMode = false;
    recordListener = null

    async mounted() {
        if(!this.moduleStore.modules.length) await this.moduleStore.initModules
        this.module = this.moduleStore.getModule(this.moduleID)
        this.moduleSingularName = this.module ? this.module.singularName : ''
        for(const field in this.moduleInfoFields) {
            const fieldName = this.moduleInfoFields[field].field
            const translationKey = `${this.moduleID}.fields.${fieldName}`
            if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                this.moduleInfoFields[field].label = store.state.fieldTranslations[translationKey]
            }
            this.infoFields.push(this.moduleInfoFields[field])
        }
        this.infoFields = sortList(this.infoFields, 'order')
        this.relatedRecordData = this.formData.data
    }
    
    getFieldValue(fieldName: string) {
      const field = this.moduleStore.getField(this.moduleID, fieldName)
      if(!field) return ''
      let fieldType = field.getFieldType()
      if(fieldType === FieldType.CALCULATEDFIELD) fieldType = field['fieldType']
      if(fieldType === FieldType.RELATEDFIELD) {
        return this.relatedRecordData && !Utils.isRelatedFieldNotFilled(this.relatedRecordData[fieldName]) ? field.getFormattedValue(this.relatedRecordData[fieldName]) : '-'
      }
      const value = this.relatedRecordData && !Utils.isEmpty(this.relatedRecordData[fieldName]) ? field.getFormattedValue(this.relatedRecordData[fieldName]) : '-'
      if(fieldType === FieldType.DROPDOWN) {
        const translationKey = `${this.moduleID}.dropDowns.${fieldName}.${value}`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
            return store.state.fieldTranslations[translationKey]
        }
      }
      return value
    }

    async getRecord(relatedModule: string, relatedRecordID: string) {
        if(!relatedRecordID || !relatedModule) return null 
        const ref = doc(db, `/tenants/${store.state.tenantID}/modules/${relatedModule}/records/${relatedRecordID}`);
        const record = (await getDoc(ref)).data();
        return record
    }

    async setupRecordListener() {
        const q = doc(db, `tenants/${store.state.tenantID}/modules/${this.moduleID}/records/${this.recordID}`);
        return onSnapshot(q, (doc) => {
            this.relatedRecordData = doc.data()
        });
    }

    get moduleInfoFields() {
        return this.moduleStore.getModuleInfoFields(this.moduleID)
    }

    get availableFields() {
        const fields: any = Object.values(this.module.fields);
        const activeFields = []
        const selectedFieldNames = []
        for(const selectedField of this.infoFields) {
            selectedFieldNames.push(selectedField.field)
        }
        for (const field of fields) {
            if(field == undefined || selectedFieldNames.includes(field.name)) {
                continue
            }
            if (!field.hidden || field.hidden == false) {
                let fieldLabel = field.label
                const translationKey = `${this.moduleID}.fields.${field.name}`
                if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                    fieldLabel = store.state.fieldTranslations[translationKey]
                }
                activeFields.push({field: field.name, label: fieldLabel})
            }
        }
        return sortList(activeFields, 'label');
    }

    deleteFieldFromOrderInfo(index: number){
      this.infoFields = this.infoFields.filter((e, ind) => index !== ind)
    }

    getSavedData(items) {
      const savedData = {}
      items.forEach((e, index) => {
        savedData[e.field] = JSON.parse(JSON.stringify(e))
        savedData[e.field].order = index + 1;
      })

      return savedData
    }

    closeEditMode() {
      this.orderInfoEditMode = false;
    }

    async saveInfoSettings() {
        const infoFields = this.getSavedData(this.infoFields)
        this.moduleStore.setModuleInfoFields(this.moduleID, infoFields)
    }

    beforeDestroy() {
        // unsubscribe firestore snapshot
        try {
            if(this.recordListener){
                this.recordListener()
            }
        } catch {}
    }

    @Emit('showRelatedRecordInfo')
    showRelatedRecordInfo() {
        return false
    }
}
