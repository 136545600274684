
import store from "@/store";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component, Vue } from "vue-property-decorator";
import OpenRelatedModal from "../../Models/OpenRelatedModal";
import Dialog from "@/components/Parts/Dialog.vue";
import {useModulesStore} from "../../../Modules/Store/ModuleStore"
import db from "@/db";
import { doc, getDoc } from "firebase/firestore";
import ModuleInfoCard from "../../../Modules/Components/ModuleInfoCard.vue"

@Component({
    components: {
      Dialog,
      ModuleInfoCard
    }
})
export default class RelatedFieldRenderer extends Vue {
  value = null;
  timeoutID1 = null;
  timeoutID2 = null;
  loaded = false;
  showAdditionalInfoModal = false;
  buttonClicks = 0;
  params: ICellRendererParams = null
  relatedData = null
  modulesStore = null
  relatedRecordData = null
  relatedModuleID = null
  additionalFields = []

  setup(props: any) {
      this.params = props.params
  }

  beforeDestroy() {
    clearTimeout(this.timeoutID1)
    clearTimeout(this.timeoutID2)
  }

  mounted() {
    this.value = this.fieldValue;
    this.modulesStore = useModulesStore()
    this.relatedModuleID = this.modulesStore.modules[this.params.context.componentParent.getModuleID()].fields[this.params.colDef.field].relatedModule
    this.loaded = true;
  }

  setupButtonClicks() {
    this.params.context.componentParent.isRelatedFieldClicked = true
    this.buttonClicks += 1
    this.timeoutID1 = setTimeout(() => {
      if(this.buttonClicks > 1) {
        this.openRelatedRecord()
      } else {
        this.clickedRelatedField(this.params)
      }
    }, 300);
  }

  get fieldValue() {
    return this.params && this.params.value && this.params.value.name ? this.params.value.name : '';
  }

  openRelatedRecord() {
    this.buttonClicks = 0
    const moduleID = this.params.context.componentParent.getModuleID()
    if(this.params.value && this.params.value.ID) {
      this.relatedData = {
        relatedRecordID: this.params.value.ID,
        relatedModuleID: store.state.dynamicModules[moduleID].fields[this.params.colDef.field].relatedModule
      }
    }
    this.params.context.componentParent.openRelatedModalForm(this.relatedData)
  }

  async clickedRelatedField(params: ICellRendererParams) {
    this.params.context.componentParent.isRelatedFieldClicked = true
    const moduleID = this.params.context.componentParent.getModuleID()
    if(!this.params.value?.ID) return null 
    const relatedData: OpenRelatedModal = {
      relatedRecordID: params.value.ID,
      relatedModuleID: store.state.dynamicModules[moduleID].fields[params.colDef.field].relatedModule
    }
    this.relatedData = relatedData
    const additionalFields = this.modulesStore.getModuleInfoFields(relatedData.relatedModuleID)
    this.relatedRecordData = await this.getRecord(relatedData.relatedModuleID, relatedData.relatedRecordID)
    for(const field in additionalFields) {
      const additionalField = additionalFields[field]
      additionalField.value = this.relatedRecordData[field]
    }
    this.additionalFields = additionalFields
  }

  openRelatedModal() {
    this.params.context.componentParent.openRelatedModalForm(this.relatedData)
  }

  showInfoRelatedRecordCard() {
    this.buttonClicks = 0
    this.params.context.componentParent.isRelatedFieldClicked = false
  }

  popupHidden() {
    this.timeoutID2 = setTimeout(() => {
      this.buttonClicks = 0
      this.params.context.componentParent.isRelatedFieldClicked = false
    }, 400);
  }

  async getRecord(relatedModule: string, relatedRecordID: string) {
    const ref = doc(db, `/tenants/${store.state.tenantID}/modules/${relatedModule}/records/${relatedRecordID}`);
    const record = (await getDoc(ref)).data();
    return record
  }
}
