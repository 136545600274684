
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { TableType } from "../Models/TableType";
import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import TableFilter from "../Models/TableFilter";
import TableTranslationsHelper from "../Helpers/TableTranslationsHelper";
import TableRowData from "../Models/TableRowData";
import TableViewHelper from "../Helpers/TableViewHelper";
import {useTablesStore} from "../Store/TableStore"
import {useModulesStore} from "../../Modules/Store/ModuleStore"
import {useRecordsStore} from "../../Records/Store/RecordStore"
import RowDataHelper from "../Helpers/RowDataHelper";
import RelatedFieldRenderer from "./FieldRenderer/RelatedFieldRenderer.vue"
import CheckboxFieldRenderer from "./FieldRenderer/CheckboxFieldRenderer.vue"
import BaseFieldRenderer from "./FieldRenderer/BaseFieldRenderer.vue"
import CheckboxFieldEditor from "./FieldEditor/CheckboxFieldEditor.vue"
import DecimalFieldEditor from "./FieldEditor/DecimalFieldEditor.vue"
import TextFieldEditor from "./FieldEditor/TextFieldEditor.vue"
import DateTimeFieldEditor from "./FieldEditor/DateTimeFieldEditor.vue";
import RelatedFieldEditor from "./FieldEditor/RelatedFieldEditor.vue";
import StatusFieldEditor from "./FieldEditor/StatusFieldEditor.vue";
import DropdownFieldEditor from "./FieldEditor/DropdownFieldEditor.vue";
import store from "@/store";
import CustomButtons from '@/components/CustomButtons/Components/CustomButtons.vue'
import ClientSideFilter from "../Models/ClientSideFilter";
import ServerSideFilter from "../Models/ServerSideFilter";
import TableViewPanel from "./ToolPanel/TableViewPanel.vue"
import BaseModalForms from "@/components/Base/Mixin/BaseModalForms.vue"
import OpenRelatedModal from "../Models/OpenRelatedModal";
import { ColumnVisibleEvent, FilterChangedEvent, GridReadyEvent, IServerSideDatasource, ProcessCellForExportParams, RowClassParams, RowDragMoveEvent, SelectionChangedEvent, SortChangedEvent } from "ag-grid-community";
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise'
import RBAC from "@/components/Settings/Sections/UserManagement/RoleManagement/Models/RBAC";
import { ServerSideOperatorType } from "../Models/ServerSideOperatorType";
import AGGridHelper from "../Helpers/AGGridHelper";
import Dialog from "@/components/Parts/Dialog.vue";
import { TOOLPANEL } from "../Models/Toolpanel";
import sortList from "@/common/helpers/utilities";
import { FieldType } from "@/components/Fields/Models/FieldType";
import TableViewColumn from "../Models/TableViewColumn";
import TableView from "../Models/TableView";
import * as lodash from "lodash";
import Utils from "@/utils/Utils";
import { collection, doc, getDoc, onSnapshot, query, setDoc, Timestamp, Unsubscribe, where } from "firebase/firestore";
import db from "@/db";

@Component({
    components: {
        AgGridVue,
        'relatedFieldRenderer': RelatedFieldRenderer,
        'checkBoxFieldRenderer': CheckboxFieldRenderer,
        'baseFieldRenderer': BaseFieldRenderer,
        'checkBoxFieldEditor': CheckboxFieldEditor,
        'decimalFieldEditor': DecimalFieldEditor,
        'textFieldEditor': TextFieldEditor,
        'dateTimeFieldEditor': DateTimeFieldEditor,
        'relatedFieldEditor': RelatedFieldEditor,
        'statusFieldEditor': StatusFieldEditor,
        'dropdownFieldEditor': DropdownFieldEditor,
        TableViewPanel,
        CustomButtons,
        Dialog,
        BaseModalForms
    }
})
export default class Table extends Vue {
    @Prop({required: true})moduleID: string;
    @Prop({required: false})relatedModuleID: string;
    @Prop({required: true})type: TableType;
    @Prop({required: true})showFilters: boolean;
    @Prop({required: true})showTotalRow: boolean;
    @Prop({required: true})inlineEditing: boolean;
    @Prop({required: false})filter: TableFilter;
    @Prop({required: false})recordID: string;
    @Prop({required: false})section_id: string;
    @Prop({required: false})section: any;
    @Prop({required: false})data;
    @Prop({required: false})initialLoadWhenInViewPort: boolean;
    @Prop({required: false})tableIsInViewPort: boolean;
    loading = true;
    doubleClicked = false;
    deleteFormsModal = false;
    defaultColumnDefinition: ColDef = { 
        filter: false,
        checkboxSelection: this.isFirstColumn,
        cellEditorPopup: true,
        sortable: true,
        filterParams: {
            maxNumConditions: 1,
            buttons: ['reset']
        }
    }
    defaultExcelExportParams = null
    recordsPerPage = 20
    showAdditionalInfoModal = false
    showRelatedModalForm = false
    showDeleteButton = false
    isTypeLineForm = false
    serverSideSortOnServer = true
    serverSideFilterOnServer = true
    customButtons = null
    modalRelatedFormID = null
    relatedModuleModal = ''
    rowModelType = 'clientSide'
    guard = null
    tableStore = null
    organizationColumns = null
    columns = null
    tableData = null
    modulesStore = null
    recordsStore = useRecordsStore()
    icons = null
    sideBar = null
    gridApi = null
    columnApi = null
    currentView = null
    isRelatedFieldClicked = false
    showCustomButtons = false
    startEditing = false
    tableLoaded = false
    totalRowClicked = false
    filterInstance = null
    context: any = null
    getRowId: any = null
    selectedTableRows: any = []
    timeRecords: any = []
    purchaseRecords: any = []
    timeRecordDeleteModal: any = false
    customViewName = this.$t('table.headers.defaultView')
    timeouts: any = []
    unsub = null
    pinnedTopData = null
    showSaveViewButton = false
    showCreateNewViewModal = false
    viewName = ''
    sharedView = false
    dataRefreshed = false
    productLinesSnapshotListener = null

    beforeMount() {
        LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-044901 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( yellowQ B.V. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( yellowQ )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( yellowQ )_need_to_be_licensed___( yellowQ )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 July 2024 )____[v2]_MTcyMDU2NjAwMDAwMA==bf9d1483a58becf4efb995f7c768c76a')
        this.context = {
          componentParent: this,
          moduleID: this.moduleID,
          sectionID: null,
          columns: null,
          currentView: this.currentView,
          initialLoadWhenInViewPort: this.initialLoadWhenInViewPort ? this.initialLoadWhenInViewPort : false,
          tableIsInViewPort: this.tableIsInViewPort ? this.tableIsInViewPort : false,
          initialLoaded: false,
          hideOverlayTimeout: null,
          saveCurrentView: 0,
          viewCreated: 0
        }
    }
    beforeDestroy() {
        //clear time out and unsubscribe firestore snapshot
        for(const timeout in this.timeouts){
            clearTimeout(timeout)
        }
        clearTimeout(this.context.hideOverlayTimeout)
        try {
            if(this.productLinesSnapshotListener){
                this.productLinesSnapshotListener()
            }
        } catch {

        }
        try {
            this.unsub()
        } catch {
            // Empty catch, because only try is not possible
        }
    }

    getRowStyle(params: RowClassParams) {
        if (params.node.rowPinned) {
            return {
                'font-weight': 'bold'
            };
        }
    }

    saveCurrentView() {
        if(this.currentView.ID === "defaultView") {
            this.showCreateNewViewModal = true
        } else {
            this.context.saveCurrentView += 1
        }
        this.showSaveViewButton = false
        return;
    }

    createNewTableView() {
        this.context.viewCreated += 1
        this.context.newView = {
            viewName: this.viewName,
            sharedView: this.sharedView,
        }
        this.showSaveViewButton = false
    }

    isFirstColumn(params) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        const thisIsFirstColumn = displayedColumns[0] === params.column
        return thisIsFirstColumn
    }

    async setRecordsPerPage(option: number) {
        this.recordsPerPage = option
        await this.tableStore.updateRecordPerPage(option, this.tableIDForLastUsedView)
        await this.refreshData(true)
    }

    async mounted() {
        if(this.section) {
            this.context.sectionID = this.section.id
        }
        this.modulesStore = useModulesStore()
        this.tableData = await this.rowData()
        this.hideOverlay()
        this.loading = false
        if(!this.typeIsNotProductLines) {
            this.isTypeLineForm = true
            this.serverSideSortOnServer = false
            this.serverSideFilterOnServer = false
            this.defaultColumnDefinition.headerCheckboxSelection = this.isFirstColumn
            this.defaultColumnDefinition.headerCheckboxSelectionFilteredOnly = true
            this.defaultColumnDefinition.rowDrag = this.isFirstColumn
        }
        this.tableLoaded = true
        if(store.state.settings && store.state.settings.recordsPerPage && store.state.settings.recordsPerPage[this.tableIDForLastUsedView]) this.recordsPerPage = store.state.settings.recordsPerPage[this.tableIDForLastUsedView]
    }

    onRowDragStart() {
        this.gridApi.setFilterModel()
        if(this.columnApi.getAllDisplayedColumns()[0].colId === 'sorting') return
        this.columnApi.setColumnVisible('sorting', true)
        this.columnApi.applyColumnState({
            state: [{ colId: 'sorting', sort: 'asc' }],
        })
        const columnFields = []
        for(const column of this.columnApi.getAllDisplayedColumns()) {
            if(column.colId == 'sorting') columnFields.unshift('sorting')
            else columnFields.push(column.colId)
        }
        if(columnFields.length) this.columnApi.moveColumns(columnFields, 0)
    }

    async onRowDragEnd(event: RowDragMoveEvent) {
        const movingNode = event.node.rowIndex;
        const overNode = event.overNode.rowIndex;
        if (movingNode !== overNode) {
            const fromIndex = event.node.rowIndex;
            const toIndex = event.overNode.rowIndex;
            const changedIndexes = this.changeIndex(this.tableData, fromIndex, toIndex);
            await this.updateSortingFields(changedIndexes)
            this.gridApi!.setRowData(changedIndexes);
            this.gridApi!.clearFocusedCell();
        }
    }

    changeIndex<T>(arr: T[], currentIndex: number, newIndex: number): T[] {
        // Check if the indices are valid
        if (currentIndex < 0 || currentIndex >= arr.length || newIndex < 0 || newIndex >= arr.length) {
            throw new Error('Invalid index');
        }
        // Remove the item from the current index
        const item = arr.splice(currentIndex, 1)[0];
        // Insert the item at the new index
        arr.splice(newIndex, 0, item);
        return arr;
    }

    async updateSortingFields(records: Array<any>) {
        for(const record in records) {
            records[record].sorting = record
            // await this.recordsStore.updateDoc(records[record], this.getModuleID(), records[record].ID)
        }
        await this.recordsStore.updateDocs(records, this.getModuleID())
    }

    async selectedColumnsChanged(event: ColumnVisibleEvent) {
        if(event.visible === false) {
            const filterModel = this.gridApi.getFilterModel()
            if(event && event.column && event.column['colId']) {
                delete filterModel[event.column['colId']]
            }
            this.gridApi.setFilterModel(filterModel)
        }
        if(this.typeIsNotProductLines && !this.doubleClicked) {
            this.refreshServerSideData(true)
        }
        if(this.totalRowClicked && event.visible) {
            await this.calculateTotals()
        }
        this.checkIfViewHasChanged()
    }

    refreshServerSideData(purge: boolean, route?: string[]) {
        this.gridApi.refreshServerSide({purge:purge})
    }

    newRecord() {
        if(this.type !== TableType.MODULE_OVERVIEW) {
            this.addRelatedRecord()
            return
        }
        this.addModuleRecord()
    }

    addRelatedRecord() {
        const moduleID = this.getModuleID();
        const relatedData: OpenRelatedModal = {
            relatedModuleID: moduleID,
            relatedRecordID: ''
        }
        this.data.copyToNewForm = this.section.copyToNewForm
        this.openRelatedModalForm(relatedData)
    }

    addModuleRecord() {
      this.$router.push({
        // name: this.isPlanBoardMode ? 'dynamicModules.formModal' : "dynamicModules.form",
        name: "dynamicModules.form",
        params: {moduleName: this.moduleID, action: "add"},
      });
    }

    cellCanBeEdited(params, action: string) {
        if(params.rowPinned === "bottom" && params.rowIndex === 1) {
            const message: any = this.$t('common.messages.rowCanNotBeChanged')
            this.$q.notify({ message: message, color: 'warning'})
            this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
            return false
        }
        if(!this.guard.check(action, this.getModuleID())) {
            const message: any = this.$t('common.messages.noPermissionToEdit')
            this.$q.notify({ message: message, color: 'warning'})
            this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
            return false
        }
        if(params.data && params.data.locked) {
            const message: any = this.$t('common.messages.recordIsLocked')
            this.$q.notify({ message: message, color: 'warning'})
            this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
            return false
        }
        if(this.checkIfSectionDisabled(params.column.colId, params.data)) {
            const message: any = this.$t('common.messages.sectionIsLocked')
            this.$q.notify({ message: message, color: 'warning'})
            this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
            return false
        }
        const field = this.modulesStore.getField(this.getModuleID(), params.column.colId)
        const column = field.getColumn(field.name, this.getModuleID())
        if(field.disabled || !column.editable) {
            const message: any = this.$t('common.messages.fieldIsDisabled')
            this.$q.notify({ message: message, color: 'warning'})
            this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
            return false
        }
        return true
    }

    cellDoubleClicked(params) {
        this.doubleClicked = true
        let action = 'update'
        if(params.rowPinned === "bottom") action = 'create'
        this.cellCanBeEdited(params, action)
        this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 500))
    }

    startCellEditing(params) {
        if(this.isRelatedFieldClicked) {
            params.api.stopEditing()
            return null;
        }
        this.startEditing = true
        let action = 'update'
        if(params.rowPinned === "bottom") action = 'create'
        if(!this.cellCanBeEdited(params, action)) {
            params.api.stopEditing()
            return null;
        }
        const moduleID = this.getModuleID();
        const fields = this.modulesStore.getMandatoryFields(moduleID)
        for(const field of fields) {
            this.columnApi.setColumnVisible(field.name, true)
        }
        if(params.rowPinned === "bottom") {
            params.data = this.addDefaultValues(params)
            if(this.section) {
                params.data = this.addCopyToNewForm(params)
            }
        }
        params.api.startEditingCell({
            rowPinned: params.rowPinned,
            rowIndex: params.rowIndex,
            colKey: params.colDef.field
        })
    }

    addCopyToNewForm(params) {
        const copyToNewFormKeys = Object.keys(this.section.copyToNewForm)
        const recordData = this.data.data
        for(const key of copyToNewFormKeys) {
            if(key === "$originRecord") {
                for(const field of this.section.copyToNewForm[key]) {
                    params.data[field] = {ID: recordData["ID"], name: recordData["name"]}
                }
            } else {
                for(const field of this.section.copyToNewForm[key]) {
                    params.data[field] = recordData[key]
                }
            }
        }
        if(!this.typeIsNotProductLines) {
            params.data["record"] = recordData["ID"]
        }
        return params.data
    }

    addDefaultValues(params) {
        const module = this.modulesStore.getModule(this.getModuleID());
        for(const f in module.fields) {
            const field = module.fields[f]
            if(field && field.defaultValue) {
                if(field.defaultValue === "now" || field.defaultValue === "$currentTime") {
                    params.data[f] = Timestamp.now()
                } else if(field.defaultValue === "$currentUser") {
                    params.data[f] = store.state.currentUser
                } else {
                    params.data[f] = field.defaultValue
                }
            }
        }
        return params.data
    }

    checkIfSectionDisabled(columnName: string, data: any): boolean {
        const checkIfSectionDisabled = this.modulesStore.checkIfSectionDisabled(this.getModuleID(), columnName, data)
        if(checkIfSectionDisabled !== false) return true;
        return false
    }

    async stoppedEditingCell(params: any) {
        this.unsub = onSnapshot(
            doc(db, `tenants/${store.state.tenantID}/modules/${this.getModuleID()}/records/${params.data.ID}`), 
            async (doc) => {
                if(doc.data()) {
                    await this.updateDataFromBackend(params, doc.data()); 
                }
                return doc;
            }
        );
        this.timeouts.push(setTimeout(() => {this.startEditing = false}, 500));
        if(params.rowPinned === "bottom" && params.rowIndex === 0) {
            this.stoppedEditingBottomCell(params)
        } else {
            this.stoppedEditingDataCell(params)
        }
        this.timeouts.push(setTimeout(() => {this.unsub()}, 10000));
    }

    fieldDisabledOrReadOnly(field) {
        if(field.disabled) {
            return true
        }
        if(field.readOnly) {
            return true
        }
        if(field.type === FieldType.AUTONUMBER) {
            return true
        }
        if(field.type === FieldType.CALCULATEDFIELD) {
            return true
        }
        return false
    }

    checkAllDataProvided(params) {
        const displayedColumns = this.columnApi.getAllDisplayedColumns()
        const mandatoryFields = this.modulesStore.getMandatoryFields(this.getModuleID())
        const fields = this.modulesStore.getFields(this.getModuleID())
        const dataKeys = []
        for(const field in params.data) {
            if(fields[field].type === FieldType.RELATEDFIELD) {
                if(!Utils.isRelatedFieldNotFilled(params.data[field])) {
                    dataKeys.push(field)
                }
            } else {
                if(!Utils.isEmpty(params.data[field])) {
                    dataKeys.push(field)
                }
            }
        }
        let validData = true
        for(const mandatoryField of mandatoryFields) {
            if(!dataKeys.includes(mandatoryField.name)) {
                validData = false;
                this.gridApi.startEditingCell({
                    rowIndex: 0,
                    colKey: mandatoryField.name,
                    rowPinned: 'bottom'
                })
                return validData
            }
        }
        return validData
    }

    async stoppedEditingBottomCell(params: any) {
        if(this.checkAllDataProvided(params)) {
            const column = this.columnApi.getAllDisplayedColumns()[0]
            const field = this.modulesStore.getField(this.getModuleID(), column.colDef.field)
            if(field.type === FieldType.RELATEDFIELD) {
                if(params.data[field.name].name === this.$t('common.fields.newRow')) {
                    delete params.data[field.name]
                }
            } else {
                if(params.data[field.name] === this.$t('common.fields.newRow')) {
                    delete params.data[field.name]
                    if(field.mandatory) {
                        this.gridApi.startEditingCell({
                            rowIndex: 0,
                            colKey: field.name,
                            rowPinned: 'bottom'
                        })
                        return
                    }
                }
            }
            const databaseResponse = await this.recordsStore.createDoc(params.data, this.getModuleID())
            if(!databaseResponse.docCount) {
                const message: any = this.$t('common.messages.errorWhileSaving')
                this.$q.notify({ message: message, color: 'negative'})
                return;
            }
            const message: any = this.$t('common.messages.recordCreated')
            this.$q.notify({ message: message, color: 'positive'})
            this.generateBottomRow()
            await this.refreshData(true)
            await this.calculateTotals() 
        }
        this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 300))
    }

    async stoppedEditingDataCell(params: any) {
        const dataToSave = {}
        const field = this.modulesStore.getField(this.getModuleID(), params.colDef.field)
        if(params.valueChanged) {
            dataToSave[params.column.colId] = params.newValue; 
            if(field.type === FieldType.RELATEDFIELD && field.relatedFieldCopy) {
                const relatedFieldCopyData = await field.executeRelatedFieldCopy(params.value, field.relatedModule)
                if(Object.keys(relatedFieldCopyData).length) {
                    for(const key in relatedFieldCopyData) {
                        params.data[key] = relatedFieldCopyData[key]
                        dataToSave[key] = relatedFieldCopyData[key]
                    }
                }
            }
            params.data[field.name] = params.newValue
            if(field.type === FieldType.DROPDOWN) {
                const originalValue = this.findKeyByValue(this.getModuleID(), field.name, params.newValue)
                params.data[field.name] = originalValue
                dataToSave[params.column.colId] = originalValue
            }
        }
        const mandatoryFields = this.modulesStore.getMandatoryFields(this.getModuleID())
        for(const mandatoryField of mandatoryFields) {
            if(mandatoryField.type == FieldType.RELATEDFIELD) {
                if(Utils.isRelatedFieldNotFilled(params.data[mandatoryField.name]) && !mandatoryField.hidden) {
                    this.$q.notify({message: "Verplicht veld niet gevuld: " + mandatoryField.label, color: 'negative'})
                    return
                }
            }
            if((Utils.isEmpty(params.data[mandatoryField.name])) && !mandatoryField.hidden) {
                this.$q.notify({message: "Verplicht veld niet gevuld: " + mandatoryField.label, color: 'negative'})
                return
            }
        }
        if(Object.keys(dataToSave).length && !Utils.isSystemField(Object.keys(dataToSave)[0])) {
            dataToSave['ID'] = params.data.ID
            await this.updateRow(dataToSave, this.getModuleID(), params.data.ID)
        }
        this.timeouts.push(setTimeout(() => {this.doubleClicked = false}, 370))
    }

    findKeyByValue(moduleID: string, fieldName: string, targetValue: any): string | null {
        const translationKey = `${moduleID}.dropDowns.${fieldName}`
        for(const index in store.state.fieldTranslations) {
            if(store.state.fieldTranslations[index] === targetValue && index.includes(translationKey)) {
                const splittedKeyName = index.split('.')
                return splittedKeyName[splittedKeyName.length - 1]
            }
        }
        return targetValue;
    }

    async updateDataFromBackend(params, data) {
        if(!lodash.isEqual(params.data, data)) {
            for(const key of Object.keys(data)) {
                if(params.data[key] !== data[key]) {
                    this.gridApi.forEachNode((rowNode) => {
                        if (!data || rowNode.data.ID !== data.ID) {
                            return;
                        }
                        const updatedData = rowNode.data;
                        updatedData[key] = data[key]
                        rowNode.updateData(updatedData);
                    })
                }
            }
        }
        await new Promise<void>(resolve => this.timeouts.push(setTimeout(async () => {
            await this.calculateTotals();
            resolve();
        }, 1000)));
        return true
    }

    async updateRow(data: TableRowData, moduleID: string, docID: string) {
        const databaseResponse = await this.recordsStore.updateDoc(data, moduleID, docID)
        if(!databaseResponse.docCount) {
            const message: any = this.$t('common.messages.errorWhileSaving')
            this.$q.notify({ message: message, color: 'negative'})
            return;
        }
        const message: any = this.$t('common.messages.recordChanged')
        this.$q.notify({ message: message, color: 'positive'})
        if(this.totalRowClicked) {
            if(this.typeIsNotProductLines) this.calculateServerSideTotals()
            else this.calculateClientSideTotals()
        }
    }

    async deleteRows(): Promise<void>{
        const moduleID = this.getModuleID();
        const selectedIDs = []
        for(const selectedRow of this.selectedTableRows) {
            const doc = await this.recordsStore.getDoc(moduleID, selectedRow.ID)
            if(!doc.locked) {
                selectedIDs.push(selectedRow.ID)
            } else {
                const message: any = this.$t('common.messages.lockedOnDelete')
                this.$q.notify({ message: message, color: 'warning'})
            }
        }
        let res = null
        if(selectedIDs.length === 1) {
            res = await this.recordsStore.deleteDoc(moduleID, selectedIDs[0])
        } else if(selectedIDs.length > 1) {
            res = await this.recordsStore.deleteDocs(moduleID, selectedIDs)
        }
        if(res.error) {
            const message: any = 'Records could not be deleted.'
            this.$q.notify({ message: message, color: 'negative'})
            return
        }
        const message: any = this.$t('common.messages.successfullyDeleted')
        this.$q.notify({ message: message, color: 'positive'})
        this.selectedTableRows = []
        this.gridApi.deselectAll()
        await this.refreshData(true)
        await new Promise<void>(resolve => this.timeouts.push(setTimeout(async () => {
            await this.calculateTotals();
            resolve();
        }, 1000)));
        return
    }

    openAdditionalInfoModal() {
        this.showAdditionalInfoModal = true;
    }

    openRelatedModalForm(relatedData: OpenRelatedModal): void{
        if (relatedData) {
          this.showRelatedModalForm = true;
          this.modalRelatedFormID = relatedData.relatedRecordID;
          this.relatedModuleModal = relatedData.relatedModuleID;
        }
        return
    }

    processCellForClipboard(params: ProcessCellForExportParams) {
        const field = this.modulesStore.getField(this.getModuleID(), params.column['colId'])
        params.value = field.getFormattedValue(params.value)
        return params.value
    }

    async closeRelatedModalForm(): Promise<void> {
        this.timeouts.push(setTimeout(async () => {
            if(this.typeIsNotProductLines) {
                this.refreshServerSideData(true)
            } else {
                this.tableData = await this.rowData()
                this.hideOverlay()
            }
        }, 500));
        this.showRelatedModalForm = false
        this.isRelatedFieldClicked = false
        return
    }

    hideOverlay() {
        this.timeouts.push(setTimeout(() => {
            this.gridApi.hideOverlay()
        }, 200));
    }

    async calculateServerSideTotals() {
        const filterModel = this.gridApi.getFilterModel()
        let additionalFilters: ServerSideFilter[] = []
        if(this.data && this.data.data.ID && this.section) {
            additionalFilters = this.getAdditionalRelatedRecordFilters()
        }
        const pinnedBottomData = await AGGridHelper.generatePinnedBottomRowDataServerSide(this.columnApi, store.state.tenantID, this.getModuleID(), this.tableIDForLastUsedView, filterModel, this.type, additionalFilters);
        this.gridApi.setPinnedBottomRowData([this.pinnedTopData, pinnedBottomData]);
        // this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }

    calculateClientSideTotals() {
        const pinnedBottomData = AGGridHelper.generatePinnedBottomRowDataClientSide(this.columnApi, this.getModuleID(), this.gridApi, this.type);
        this.gridApi.setPinnedBottomRowData([this.pinnedTopData, pinnedBottomData]);
        // this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }

    async calculateTotals() {
        if(this.typeIsNotProductLines) {
            await this.calculateServerSideTotals()
        } else {
            this.calculateClientSideTotals()
        }
    }

    async openRelatedRow(params: any) {
        if(params.rowPinned && params.rowPinned === "bottom" && params.rowIndex === 0) {
            return
        }
        if(params.rowPinned && params.rowPinned === "bottom" && params.rowIndex === 1) {
            await this.calculateTotals()
            this.totalRowClicked = true
            return
        }
        if(this.type === TableType.MODULE_OVERVIEW) this.tableStore.currentPage[this.tableIDForLastUsedView] = this.gridApi.paginationGetCurrentPage()

        const instances = this.gridApi.getCellEditorInstances(params);
        if (instances.length) {
            return
        }
        this.timeouts.push(setTimeout(() => {
            if(!this.isRelatedFieldClicked && !this.doubleClicked && !this.startEditing) {
                if(this.type === TableType.MODULE_OVERVIEW) {
                    this.$router.push({path: this.$route.path + '/edit/' + params.data.ID});
                }
                const moduleID = this.getModuleID();
                const relatedData: OpenRelatedModal = {
                    relatedModuleID: moduleID,
                    relatedRecordID: params.data.ID
                }
                this.openRelatedModalForm(relatedData)
            }
        }, 300));
    }

    openDeleteModal() {
        this.timeRecords = []
        this.purchaseRecords = []
        this.selectedTableRows = this.gridApi.getSelectedRows()
        for(const record of this.selectedTableRows) {
            if(record.timeRecord) {
                this.timeRecords.push(record)
            }
            if(record.purchaseRecord) {
                this.purchaseRecords.push(record)
            }
        }
        if(this.timeRecords.length || this.purchaseRecords.length) {
            this.timeRecordDeleteModal = true
        } else {
            this.deleteFormsModal = true
        }
    }

    async created() {
        if(this.typeIsNotProductLines) {
            this.rowModelType = 'serverSide'
        }
        const moduleID = this.getModuleID();
        this.getRowId = (params) => {
            return params.data.ID != null ? 'id-' + params.data.ID : ''
        };
        this.icons = {
            'tableViewPanel': '<div style="font-size: 24px;"><i class="material-icons">preview</i></div>',
        };
        this.sideBar = TOOLPANEL
        this.sideBar.toolPanels[1].labelDefault = this.$t('table.agGrid.views')
        this.guard = new RBAC({module: moduleID});
        // WARNING: Fetching custom buttons should be done last, because the table will init and not wait for the await to return
        if(this.section) {
            const buttons = await this.getCustomButtons(this.moduleID, this.section)
            this.customButtons = buttons
        }
        this.showCustomButtons = true
    }

    async getCustomButtons(moduleID: string, section?: any) {
        if(section.id) {
            const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/customButtonsOrder/${moduleID}-${section.id}`);
            const record = await getDoc(ref);
            if(record.exists()) {
                const recordData = record.data()
                for(const customButton in recordData.customButtons) {
                    if(!section.customButtons[customButton]) continue
                    section.customButtons[customButton].order = recordData.customButtons[customButton]
                }
                return section.customButtons
            }
        }
        return this.section.customButtons
    }

    toggleViewToolpanel() {
        if(!this.gridApi.isToolPanelShowing()) {
            this.gridApi.openToolPanel('tableViewPanel')
        } else {
            this.gridApi.openToolPanel(false)
        }
    }

    getContextMenuItems() {
        const app = this
        return ['copy', 'copyWithHeaders', 'paste', {
          name: 'Records per pagina',
          subMenu: [
            {
              name: '10',
              action: async () => {
                await app.setRecordsPerPage(10)
              },
            },
            {
              name: '20',
              action: async () => {
                await app.setRecordsPerPage(20)
              },
            },
            {
              name: '30',
              action: async () => {
                await app.setRecordsPerPage(30)
              },
            },
            {
              name: '50',
              action: async () => {
                await app.setRecordsPerPage(50)
              },
            },
            {
              name: '75',
              action: async () => {
                await app.setRecordsPerPage(75)
              },
            },
            {
              name: '100',
              action: async () => {
                await app.setRecordsPerPage(100)
              },
            },
          ],
        },]
    }

    async onGridReady(params: GridReadyEvent) {
        this.tableStore = useTablesStore();
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.organizationColumns = await this.tableStore.organizationColumns;
        if(this.typeIsNotProductLines) {
            const moduleID = this.getModuleID()
            let additionalFilters: ServerSideFilter[] = []
            if(this.data && this.data.data.ID && this.section) {
                additionalFilters = this.getAdditionalRelatedRecordFilters()
            }
            const dataSource: IServerSideDatasource = RowDataHelper.getDataSource(store.state.tenantID, moduleID, this.gridApi, this.columnApi, this.tableIDForLastUsedView, additionalFilters)
            this.gridApi.setServerSideDatasource(dataSource)
        }
        this.timeouts.push(setTimeout(() => {
            if(!this.typeIsNotProductLines) this.gridApi.hideOverlay()
            this.generateBottomRow()
        }, 500));
    }

    checkLastUsedViewExists() {
        return store.state.settings && store.state.settings.lastUsedTableViews && store.state.settings.lastUsedTableViews[this.tableID]
    }

    async onChangeInColumns() {
        this.generateBottomRow()
        if(this.tableLoaded && this.totalRowClicked) {
            await this.calculateTotals()
        }
        if(!this.typeIsNotProductLines) {
            this.totalRowClicked = true
            await this.refreshData(true)
        }
        this.checkIfViewHasChanged()
        await this.gridApi.deselectAll()
    }

    checkIfViewHasChanged(event?: FilterChangedEvent | SortChangedEvent) {
        const columnsChanged = this.checkColumnsChanged()
        const filterChanged = this.checkFiltersChanged(event)
        const sortChanged = this.checkSortChanged(event)

        if( columnsChanged || filterChanged || sortChanged) this.showSaveViewButton = true
        else this.showSaveViewButton = false
    }

    checkColumnsChanged(): boolean {
        const displayedColumns = []
        for(const column of this.columnApi.getAllDisplayedColumns()) {
            displayedColumns.push(column.colId)
        }
        const viewColumns = []
        for(const column of this.currentView.columns) {
            viewColumns.push(column.colId)
        }
        if(!lodash.isEqual(viewColumns, displayedColumns)) {
            return true
        }
        return false
    }

    checkFiltersChanged(event): boolean {
        const checkFiltersChanged = []
        if (event && event.context && event.type === 'filterChanged') {
            const filterModels = this.gridApi.getFilterModel();
            const value =  event.columns.some(column => {
                const filterModel = filterModels[column.colId]
                const { colId } = column;
                const index = event.context.currentView.columns.findIndex(c => c.colId === colId);
                const currentViewColumn = event.context.currentView.columns[index];
                const field = this.modulesStore.getField(this.getModuleID(), currentViewColumn.field)
                if(currentViewColumn?.type !== filterModel?.type) return true
                if(field?.type === FieldType.DROPDOWN || field?.type === FieldType.CHECKBOX) {
                    if (filterModel) {
                        return !lodash.isEqual(currentViewColumn.values, filterModel.values);
                    } else {
                        return !!currentViewColumn.values;
                    }
                } else if (field?.type === FieldType.DATETIME) {
                    if(currentViewColumn.dateFrom !== filterModel?.dateFrom) return true
                    if(currentViewColumn.dateTo !== filterModel?.dateTo) return true
                    return false
                } else {
                    if (filterModel) {
                        return currentViewColumn?.filter?.toLowerCase() !== filterModel.filter?.toLowerCase();
                    } else {
                        return !!currentViewColumn.filter;
                    }
                }
            });
            checkFiltersChanged.push(value)
        }
        if(checkFiltersChanged.includes(true)) return true
        return false
    }

    checkSortChanged(event): boolean {
        if (event && event.context && event.type === 'sortChanged') {
            const displayedColumns = event.columnApi.getAllDisplayedColumns()
            const index = displayedColumns.findIndex(c => c.sort)
            const columnSorted = displayedColumns[index]
            const currentViewColumn = event.context.currentView.columns[index];
            if((columnSorted && currentViewColumn) && (columnSorted.sort !== currentViewColumn.sort)) {
                return true
            }
        }
        return false
    }

    generateBottomRow() {
        const firstColumn = this.columnApi.getAllDisplayedColumns()[0]
        if(firstColumn) {
            const field = this.modulesStore.getField(this.getModuleID(), firstColumn.colId)
            if(field.getFieldType() !== FieldType.RELATEDFIELD) {
                this.pinnedTopData = {[firstColumn.getColId()]:  this.$t('common.fields.newRow')}
                const pinnedBottomData = {[firstColumn.getColId()]:  this.$t('common.buttons.clickToCalculateTotal')}
                this.gridApi.setPinnedBottomRowData([this.pinnedTopData, pinnedBottomData]);
                // this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
            } else {
                this.pinnedTopData = {[firstColumn.getColId()]:  {ID: '', name: this.$t('common.fields.newRow')}}
                const pinnedBottomData = {[firstColumn.getColId()]:  {ID: '', name: this.$t('common.buttons.clickToCalculateTotal')}}
                this.gridApi.setPinnedBottomRowData([this.pinnedTopData, pinnedBottomData]);
                // this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
            }
        }
    }

    getAdditionalRelatedRecordFilters(): ServerSideFilter[] {
        const additionalFilters: ServerSideFilter[] = []
        additionalFilters.push({
            moduleID: this.section.relatedModule,
            field: this.section.relatedByField + '.ID',
            operator: ServerSideOperatorType.ISEQUAL,
            value: this.data.data.ID
        })
        if(this.section && this.section.filter) {
            for(const filter of this.section.filter) {
                const additionalFilter = {
                    moduleID: this.section.relatedModule,
                    field: filter.field,
                    operator: filter.operator,
                    value: filter.value
                }
                additionalFilters.push(additionalFilter)
            }
        }
        return additionalFilters
    }

    async getColumnDefinitions(): Promise<ColDef[]> {
        const moduleID = this.getModuleID();
        let columns: ColDef[] = []
        if(store.state.settings && store.state.settings.lastUsedTableViews && store.state.settings.lastUsedTableViews[this.tableIDForLastUsedView] === 'defaultView') {
            const tableView = await this.tableStore.getTableViewByID(this.tableID, store.state.settings.lastUsedTableViews[this.tableIDForLastUsedView], this.type)
            if(!Utils.isEmpty(tableView)) {
                this.timeouts.push(setTimeout(() => {
                    this.filterInstance = this.gridApi.getFilterModel();
                    for(const column of tableView.columns) {
                        this.filterInstance[column.field] = column
                    }
                    this.gridApi.setFilterModel(this.filterInstance)
                }, 800));
            }
            const array = this.organizationColumns[moduleID]
            const tableFilter: any = {
                columns: array,
                filter: {}
            }
            columns = await TableViewHelper.getColumnDefFromTableView(tableFilter, moduleID, this.typeIsNotProductLines)
            this.loading = false 
            return columns;
        }
        if(this.filter) {
            this.loading = false;
            return columns = await TableViewHelper.getColumnDefFromTableView(this.filter, moduleID, this.typeIsNotProductLines)
        } else if(store.state.settings && store.state.settings.lastUsedTableViews && store.state.settings.lastUsedTableViews[this.tableIDForLastUsedView]) {
            const tableView = await this.tableStore.getTableViewByID(this.tableID, store.state.settings.lastUsedTableViews[this.tableIDForLastUsedView], this.type)
            if(tableView && tableView.name){
                this.customViewName = tableView.name
                const columnFields = []
                if(this.gridApi) {
                    this.filterInstance = this.gridApi.getFilterModel();
                    for(const column of tableView.columns) {
                        columnFields.push(column.field)
                        this.filterInstance[column.field] = column
                    }
                }
                const tableFilter: any = {
                    columns: columnFields,
                    filter: {}
                }
                this.loading = false;
                return columns = await TableViewHelper.getColumnDefFromTableView(tableFilter, moduleID, this.typeIsNotProductLines)
            }
        } else {
            const array = this.organizationColumns[moduleID]
            const tableFilter: any = {
                columns: array,
                filter: {}
            }
            columns = await TableViewHelper.getColumnDefFromTableView(tableFilter, moduleID, this.typeIsNotProductLines)
        }
        this.loading = false;
        return columns
    }

    initializeProductLinesSnapshotListener(moduleID: string, recordID: string): Unsubscribe {
        const q = query(collection(db, `tenants/${store.state.tenantID}/modules/${moduleID}/records`), where('record', "==", recordID));
        return onSnapshot(q, (querySnapshot) => {
            const rowData = [];
            querySnapshot.forEach((doc) => {
                rowData.push(doc.data());
            });
            const filter: ClientSideFilter[] = store.state.dynamicModules[this.moduleID].sections[this.section.id]?.filter
            const filteredData = RowDataHelper.clientSideFiltering(rowData, filter)
            this.gridApi.setRowData(filteredData ? filteredData : null)
            this.calculateClientSideTotals()
        });
    }

    async rowData() {
        const moduleID = this.getModuleID();
        if(!this.typeIsNotProductLines) {
            if(!this.productLinesSnapshotListener){
                this.productLinesSnapshotListener = this.initializeProductLinesSnapshotListener(moduleID, this.recordID);
            } 
            const rowData = await RowDataHelper.fetchDataFromFirebase(moduleID, this.recordID)
            const filter: ClientSideFilter[] = store.state.dynamicModules[this.moduleID].sections[this.section.id]?.filter
            const filteredData = RowDataHelper.clientSideFiltering(rowData, filter)
            return filteredData ? filteredData : null
        }
        return null
    }

    async onSelectionChanged(event: SelectionChangedEvent) {
        const selectedRows = event.api.getSelectedRows()
        if(selectedRows.length) {
            this.showDeleteButton = true
        } else {
            this.showDeleteButton = false
        }
        await this.calculateTotals()
        this.totalRowClicked = true
    }
    
    async onFilterChanged(event: FilterChangedEvent) {
        if(this.tableLoaded && this.totalRowClicked) {
            await this.calculateTotals()
        } 
        if(this.type === TableType.MODULE_OVERVIEW && event.afterFloatingFilter) {
            if(this.currentView === null || this.currentView === undefined) {
                this.currentView = {ID: 'defaultView', name: 'defaultView'}
            }
            const columns: TableViewColumn[] = this.getColumns()
            const view: TableView = {
                columns: columns,
                name: this.currentView.name,
                ID: this.currentView.ID,
                createdBy: store.state.currentUser,
                sharedView: false
            }
            await this.tableStore.updateLastUsedView(view, this.tableIDForLastUsedView)
        }

        this.checkIfViewHasChanged(event)
    }

    onSortChanged(event: SortChangedEvent) {
        this.checkIfViewHasChanged(event)
    }

    getColumns() {
      const filterInstance = this.gridApi.getFilterModel();
      const displayedColumns = this.gridApi.columnModel.displayedColumns
      const columnsWithFilter = []
      const columns = []
      for(const filter in filterInstance) {
        columnsWithFilter.push(filter)
      }
      for(const column of displayedColumns) {
          if(!columnsWithFilter.includes(column.colId)) {
              columns.push({
                  field: column.colId,
                  pinned: column.pinned,
                  actualWidth: column.actualWidth,
                  sort: column.sort ? column.sort : null
              })
          }
      }
      for(const filter in filterInstance) {
          for(const column in displayedColumns) {
              if(displayedColumns[column].colId == filter) {
                  filterInstance[filter].field = filter
                  columns.splice(Number(column), 0, filterInstance[filter])
              }
          }
      }
      return columns
    }

    async refreshData(purge: boolean) {
        if(this.typeIsNotProductLines) {
            this.refreshServerSideData(purge)
        } else {
            this.tableData = await this.rowData()
            this.hideOverlay()
        }
        if(this.totalRowClicked) {
            await this.calculateTotals()
        }
    }

    onStoreRefreshed() {
        this.dataRefreshed = true
    }

    async exportData(settings) {
        const app = this
        this.dataRefreshed = false
        let recordsPerPage = 20
        if(store.state.settings.recordsPerPage && store.state.settings.recordsPerPage[this.tableIDForLastUsedView]) {   
            recordsPerPage = store.state.settings.recordsPerPage[this.tableIDForLastUsedView]
        } else {
            if(!store.state.settings.recordsPerPage) store.state.settings.recordsPerPage = {}
            store.state.settings.recordsPerPage[this.tableIDForLastUsedView] = 20
        }
        if(this.typeIsNotProductLines) {
            if(settings.allData) {
                this.recordsPerPage = 5000
                store.state.settings.recordsPerPage[this.tableIDForLastUsedView] = 5000
                await this.refreshData(false)
                while (!this.dataRefreshed) {
                    await new Promise<void>(resolve => this.timeouts.push(setTimeout(async () => {
                        await this.refreshData(false)
                        resolve();
                    }, 500)));
                }
            }
        }

        this.defaultExcelExportParams = {
            fileName: settings.fileName,
            exportMode: settings.fileType,
            skipPinnedBottom: !settings.showTotalRow,
            skipColumnHeaders: !settings.includeHeaders,
            sheetName: this.customViewName,
            author: store.state.currentUser.name,
            processCellCallback(params: ProcessCellForExportParams) {
                const field = app.modulesStore.getField(app.getModuleID(), params.column['colId'])
                if(field.type === FieldType.RELATEDFIELD && Utils.isEmpty(params.value?.ID)) params.value = '-'
                if(settings.includeRelatedFieldIDs && field.type === FieldType.RELATEDFIELD && !Utils.isEmpty(params.value?.ID)) params.value = `${params.value?.ID} - ${params.value?.name}`
                else params.value = field.getFormattedValue(params.value)
                
                if(Utils.isEmpty(params.value)) params.value = '-'
                return params.value
            }
        }
        this.gridApi.exportDataAsExcel(this.defaultExcelExportParams)
        this.recordsPerPage = recordsPerPage
        store.state.settings.recordsPerPage[this.tableIDForLastUsedView] = recordsPerPage
        await this.refreshData(true)
    }

    async customButtonsChanged(customButtonsList) {
        const customButtons = {}
        for(const i in customButtonsList) {
          customButtons[customButtonsList[i].name] = i
        }
        let ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/customButtonsOrder/${this.moduleID}-${this.section.id}`);
        if(this.type === TableType.MODULE_OVERVIEW) ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.currentUser.ID}/customButtonsOrder/${this.moduleID}`);
        await setDoc(ref, {customButtons: customButtons});
        return
    }

    get tableID(){
        switch (this.type) {
            case TableType.MODULE_OVERVIEW:
                return this.moduleID
            case TableType.PRODUCT_LINES:
            case TableType.RELATED_RECORDS:
                return this.getModuleID()
            case TableType.PLANBOARD:
                return 'table-planboard'
            default:
                return 'table'
        }
    }

    get tableIDForLastUsedView(){
        switch (this.type) {
            case TableType.MODULE_OVERVIEW:
                return this.moduleID
            case TableType.PRODUCT_LINES:
            case TableType.RELATED_RECORDS:
                return this.moduleID + '-' + this.getModuleID()
            case TableType.PLANBOARD:
                return 'table-planboard'
            default:
                return 'table'
        }
    }

    get styleClass(){
        // you can define a different class per Table type here.
        switch (this.type) {
            case TableType.MODULE_OVERVIEW:
            case TableType.PRODUCT_LINES:
            case TableType.RELATED_RECORDS:
            case TableType.PLANBOARD:
                return 'my-sticky-header-column-table table-column-order-' + this.tableID
            default: 
                return 'my-sticky-header-column-table table-column-order-' + this.tableID
        }
    }
    

    get selectedRows() {
        return this.gridApi.getSelectedRows()
    }

    get isDeleteButtonDisabled(): boolean {
      return !this.guard.check('delete');
    }

    get isDeleteButtonHidden(): boolean {
      return this.guard.hide('delete');
    }
    
    get isAddButtonDisabled(): boolean {
      return !this.guard.check('create');
    }

    get isAddButtonHidden(): boolean {
      return this.guard.hide('create');
    }

    get typeIsNotProductLines() {
        return this.type !== TableType.PRODUCT_LINES
    }

    get translations() {
        const tableTranslationsHelper = new TableTranslationsHelper()
        return tableTranslationsHelper.getTranslations()
    }

    getModuleID(): string {
        switch (this.type) {
            case TableType.PRODUCT_LINES:
                return this.section.lineType;
            case TableType.RELATED_RECORDS:
                return this.relatedModuleID
            default:
                return this.moduleID;
        }
    }

    get moduleLabel(): string {
        return this.type === TableType.PRODUCT_LINES || this.type === TableType.RELATED_RECORDS ? this.section.label : this.modulesStore.modules[this.moduleID].singularName
    }

    @Watch('context.currentView') 
    viewHandler(view: any) {
        if(view && view.name) {   
            this.customViewName = view.name
            this.currentView = view
        }
    }
    @Watch('context.columns') 
    columnsHandler(columns: any) {
        if((this.columns && this.columns.length) !== (columns && columns.length)) {
            this.columns = columns
        }
    }
    @Watch('tableIsInViewPort') 
    tableIsInViewPortHandler(tableIsInViewPort: boolean) {
        if(tableIsInViewPort && !this.context.initialLoaded) {
            this.context.initialLoaded = true;
            this.refreshServerSideData(true)
        }
    }
}
