
import Utils from "@/utils/Utils";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component, Vue } from "vue-property-decorator";
import {useModulesStore} from "../../../Modules/Store/ModuleStore"

@Component({})
export default class BaseFieldRenderer extends Vue {
    value = null;
    loaded = false;
    params: ICellRendererParams = null
    modulesStore = useModulesStore()

    setup(props: any) {
        this.params = props.params
    }
    mounted() {
        this.value = this.fieldValue;
        this.loaded = true;
    }

    get fieldValue() {
        if (this.params) {
            if (!Utils.isEmpty(this.params.value)) {
                return this.modulesStore.modules[this.params.context.componentParent.getModuleID()].fields[this.params.colDef['field']].getFormattedValue(this.params.value, this.params.context.componentParent.getModuleID())
            }
        }
        return null;
    }
}
