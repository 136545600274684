import { render, staticRenderFns } from "./DecimalFieldEditor.vue?vue&type=template&id=b51b0850&"
import script from "./DecimalFieldEditor.vue?vue&type=script&lang=ts&"
export * from "./DecimalFieldEditor.vue?vue&type=script&lang=ts&"
import style0 from "./DecimalFieldEditor.vue?vue&type=style&index=0&id=b51b0850&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QInput,QIcon,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInput,QIcon,QField})
